import React from "react";
import "bulma-pageloader";

const Loader = () => {
  return (
    <div className="pageloader is-active">
      <span className="title">Laden...</span>
    </div>
  );
};

export default Loader;
