import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Loader from "../../../components/Loader";
import Table from "../../../components/Table";
import { MY_CONFIG } from "../../../utils/config";
import { refactorPath } from "../../../utils/functions";
import { supabase } from "../../../utils/supbaseClient";
import { TABLE_NAMES } from "../../../utils/tableNames";

const columns = [
  { headerName: "ID", field: "id", type: "number", hide: true },
  { headerName: "Namen", field: "names", type: "string" },
  { headerName: "Datum", field: "created_at", type: "date" },
];

const SignUps = () => {
  const [values, setValues] = useState({ signUps: [], loading: true });

  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      const { data, error } = await supabase.from(TABLE_NAMES.registrations)
        .select(`
          id,
          created_at,
          names,
          public_uuid
        `);

      if (error) {
        console.error(error);
      }

      const rows = data.map((item) => {
        return {
          ...item,
          created_at: new Date(item.created_at),
        };
      });

      setValues((state) => ({ ...state, signUps: rows, loading: false }));
    })();
    return () => {};
  }, []);

  const onEdit = (data) => {
    navigate(
      refactorPath(
        MY_CONFIG.routes.user.setRegistration.path,
        ":uuid",
        data.public_uuid
      )
    );
  };

  if (values.loading) {
    return <Loader />;
  }

  const addButton = (
    <button
      title="Anmeldung hinzufügen"
      className="button is-primary"
      onClick={() =>
        navigate(
          refactorPath(
            MY_CONFIG.routes.user.setRegistration.path,
            ":uuid",
            "new"
          )
        )
      }
    >
      <i className="fa fa-plus"></i>
    </button>
  );

  return (
    <div className="container is-fullwidth pt-4">
      <h1 className="title">Anmeldungen</h1>
      <Table
        columns={columns}
        data={values.signUps}
        loading={values.loading}
        onEdit={onEdit}
        customButtons={[addButton]}
        sortBy={{ column: "created_at", type: "desc" }}
        isBordered
        print
      />
    </div>
  );
};

export default SignUps;
