import React, { useEffect, useState } from "react";
import { LOCAL_STORAGE_KEYS } from "../utils/config";
import { supabase } from "../utils/supbaseClient";
import { TABLE_NAMES } from "../utils/tableNames";

const SelectKinderstunde = ({ ...props }) => {
  const [values, setValues] = useState({
    kinderstunde: [],
    loading: true,
    error: "",
  });

  useEffect(() => {
    (async () => {
      let localKinderstunden = window.localStorage.getItem(
        LOCAL_STORAGE_KEYS.KINDERSTUNDEN
      );

      if (localKinderstunden) {
        try {
          localKinderstunden = JSON.parse(localKinderstunden);

          const olderThan1Hour =
            new Date().getTime() -
              new Date(localKinderstunden.leaseTime).getTime() >
            1000 * 60 * 60;

          if (!olderThan1Hour) {
            setValues((state) => ({
              ...state,
              kinderstunde: localKinderstunden.kinderstunden,
              loading: false,
            }));
            return;
          }
        } catch (err) {
          console.warn(err);
        }
      }

      const { data, error } = await supabase
        .from(TABLE_NAMES.childrensLessons)
        .select("id, name");

      if (error) {
        console.error(error);
        setValues((state) => ({
          ...state,
          loading: false,
          error: error.message,
        }));

        return;
      }

      window.localStorage.setItem(
        LOCAL_STORAGE_KEYS.KINDERSTUNDEN,
        JSON.stringify({
          leaseTime: new Date().getTime(),
          kinderstunden: data,
        })
      );

      setValues((state) => ({ ...state, kinderstunde: data, loading: false }));
    })();

    return () => {};
  }, []);

  return (
    <>
      {values.loading ? (
        <progress
          className="progress is-small my-auto mx-auto"
          max="100"
          style={{ maxWidth: 300 }}
        ></progress>
      ) : (
        <div className="field">
          <label htmlFor="selectKinderstunde" className="label">
            Kinderstunde
          </label>
          <div className="select is-fullwidth">
            <select {...props} id="selectKinderstunde">
              {values.kinderstunde.map((k) => (
                <option value={k.id} key={k.id}>
                  {k.name}
                </option>
              ))}
            </select>
          </div>
        </div>
      )}
    </>
  );
};

export default SelectKinderstunde;
