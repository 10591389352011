import React, { useState } from "react";
import ColumnsVisibility from "./ColumnsVisibility";
import { resolveValue } from "./helpers";
import Print from "./Print";
import Searchbar from "./Searchbar";
import "./../../styles/table.css";

const Table = ({
  columns = [],
  data = [],
  loading = false,
  tableStyles = {},
  rowStyles = {},
  isBordered = false,
  isStriped = false,
  isNarrowed = false,
  isHoverable = false,
  onEdit,
  print = false,
  customButtons,
  sortBy,
}) => {
  let sorting = { sortByColumn: columns[0], asc: true };
  if (sortBy) {
    sorting.sortByColumn = columns.find((col) => col.field === sortBy.column);
    sorting.asc = sortBy.type === "asc";
  }
  const [values, setValues] = useState({
    sortByColumn: sorting.sortByColumn,
    asc: sorting.asc,
    rows: [...data],
    columns,
  });

  // useEffect(() => {
  //   setValues((state) => ({ ...state, rows: data }));
  //   return () => {};
  // }, [data]);

  const rootStyles = { ...tableStyles };
  const tableCls = [];
  if (isBordered) tableCls.push("is-bordered");
  if (isStriped) tableCls.push("is-striped");
  if (isNarrowed) tableCls.push("is-narrow");
  if (isHoverable) tableCls.push("is-hoverable");

  const tableRowStyles = { ...rowStyles };

  let onEditFunc = onEdit || undefined;

  if (loading)
    return (
      <div className="is-flex is-justify-content-center is-flex-direction-column has-text-centered">
        <progress
          className="progress is-small is-primary mx-auto"
          max="100"
          style={{ maxWidth: 250 }}
        ></progress>
        Laden...
      </div>
    );

  const compare = (a, b) => {
    switch (typeof a) {
      case "string":
        return a.localeCompare(b);
      case "undefined":
      case "object":
        if (
          Object.prototype.toString.call(a) === "[object Date]" &&
          Object.prototype.toString.call(b) === "[object Date]"
        ) {
          return a.getTime() - b.getTime();
        }
        console.info("unhandled value found", a, b);
        return 0;
      default:
        return a - b;
    }
  };

  const sort = (a, b) => {
    let result = 0;

    const { customSortFieldPath, field } = values.sortByColumn;
    if (typeof customSortFieldPath !== "undefined") {
      result = compare(
        resolveValue(customSortFieldPath, a),
        resolveValue(customSortFieldPath, b)
      );
    } else if (typeof a[field] === "object") {
      if (typeof a[field].value !== "undefined") {
        result = compare(a[field].index, b[field].index);
      } else if (typeof a[field].getDate !== "undefined") {
        result = compare(
          new Date(a[field]).getTime(),
          new Date(b[field]).getTime()
        );
      }
    } else {
      result = compare(a[field], b[field]);
    }

    return result * (values.asc ? 1 : -1);
  };

  return (
    <div className="awesome-table pb-5">
      <div className="toolbar pb-3">
        <ColumnsVisibility
          columns={values.columns}
          onChange={(columns) => setValues({ ...values, columns })}
        />
        {print && <Print />}
        {customButtons &&
          customButtons.map((btn, index) => <span key={index}>{btn}</span>)}
        <Searchbar
          className="margin-auto-left"
          onChange={(rows) => setValues({ ...values, rows })}
          rows={data}
          columns={columns}
        />
      </div>
      <div className="table-container">
        <table
          className={
            "table is-responsive is-striped is-fullwidth " + tableCls.join(" ")
          }
          style={rootStyles}
        >
          <thead>
            <tr></tr>
            <tr>
              {values.columns
                .filter((col) => !col.hide)
                .map((col, index) => {
                  const isCurrent = values.sortByColumn.field === col.field;
                  const isAsc = isCurrent ? !values.asc : true;

                  const sortColumn = () => {
                    setValues({
                      ...values,
                      sortByColumn: col,
                      asc: isAsc,
                    });
                  };
                  return (
                    <th
                      key={index}
                      className="onHover-show-btn"
                      onClick={sortColumn}
                    >
                      <span>{col.headerName || col.field || ""}</span>
                      <button
                        className={
                          "is-pulled-right btn-sorting" +
                          (isCurrent ? " is-visible" : "")
                        }
                        onClick={() => sortColumn}
                      >
                        {isCurrent && values.asc ? (
                          <i className="fas fa-chevron-down"></i>
                        ) : (
                          <i className="fas fa-chevron-up"></i>
                        )}
                      </button>
                    </th>
                  );
                })}
              {onEdit && <th className="no-print">Actions</th>}
            </tr>
          </thead>
          <tbody>
            {values.rows.sort(sort).map((row, index) => {
              return (
                <tr key={index} style={tableRowStyles}>
                  {values.columns
                    .filter((col) => !col.hide)
                    .map((col, i) => {
                      const value = resolveValue(col.field, row);
                      let text = "";
                      if (typeof col.formatCell !== "undefined") {
                        text = col.formatCell(value);
                      } else if (col.type === "date") {
                        text = new Date(value).toLocaleString();
                      } else if (col.field.indexOf(".") > -1) {
                        text = resolveValue(col.field, row);
                      } else if (typeof value === "object") {
                        if (value === null) {
                          text = "-";
                        } else {
                          text = value.title || value.name || "";
                        }
                      } else {
                        text = value;
                      }

                      return <td key={i}>{text}</td>;
                    })}

                  {onEdit && (
                    <td className="has-text-centered action-cell no-print">
                      <button
                        className="button is-small"
                        onClick={() => onEditFunc(row)}
                      >
                        <i className="far fa-edit"></i>
                      </button>
                    </td>
                  )}
                </tr>
              );
            })}

            {values.rows.length === 0 && !loading && (
              <tr>
                <td colSpan={values.columns.length}>Keine Einträge gefunden</td>
              </tr>
            )}
          </tbody>
          {values.rows.length > 0 && (
            <tfoot>
              <tr>
                <td colSpan={values.columns.length}>
                  {data.length !== values.rows.length
                    ? `${values.rows.length} von ${data.length} Einträgen`
                    : values.rows.length + " Einträge"}
                </td>
              </tr>
            </tfoot>
          )}
        </table>
      </div>
    </div>
  );
};

export default Table;
