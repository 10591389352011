import React from "react";

const Footer = () => {
  return (
    <footer className="footer" style={{ padding: 5 }}>
      <div className="container">
        <div className="content has-text-centered">
          <p style={{ fontSize: "0.8rem" }}>
            &copy; Copyright {new Date().getFullYear()}{" "}
            <a href="https://fecg-hennef.de">FECG-Hennef.de</a>
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
