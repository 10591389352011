import React from "react";
import { useNavigate } from "react-router-dom";
import { MY_CONFIG } from "../../utils/config";
import { translate } from "../../utils/i18n";
import { isEmpty, validateEmail } from "./../../utils/functions";
import { supabase } from "./../../utils/supbaseClient";

const SignUp = () => {
  const [values, setValues] = React.useState({
    email: "",
    password: "",
    passwordVisible: false,
    errors: {},
    session: "",
    loading: false,
  });

  const navigate = useNavigate();

  const onInput = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const validate = () => {
    const newErrors = {};
    if (values.email.length === 0) {
      newErrors["email"] = "Bitte eine E-Mail eintragen";
      if (!validateEmail(values.email)) {
        newErrors["email"] = "Keine gültige E-Mail";
      }
    }

    if (values.password.length === 0) {
      newErrors["password"] = "Bitte eine Passwort eintragen";
      if (values.password.length < 6) {
        newErrors["password"] = "Mindestens 6 Zeichen";
      }
    }

    setValues({ ...values, errors: newErrors });

    return isEmpty(newErrors);
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    if (!validate()) return;
    setValues({ ...values, loading: true, errors: {} });

    const { error } = await supabase.auth.signUp({
      email: values.email,
      password: values.password,
    });

    if (error) {
      setValues({
        ...values,
        errors: {
          loginError: translate(error.message),
        },
      });

      console.error(error);
      return;
    }
    navigate(MY_CONFIG.routes.auth.login.path + "?m=r");
    window.location.reload();
  };

  return (
    <section
      style={{
        maxWidth: "600px",
      }}
    >
      <div className="columns">
        <div className="column left has-text-centered">
          <h1 className="title is-3">Registrieren</h1>
          <h1 className="subtitle is-5 has-text-centered">Verwaltung</h1>
          <form className="mt-3" onSubmit={onSubmit} noValidate>
            <div className="field">
              <div className="control">
                <input
                  className="input is-medium"
                  type="email"
                  placeholder="name@mail.com"
                  onChange={onInput}
                  name="email"
                />
              </div>
              {values.errors["email"] && (
                <p className="help is-danger">{values.errors["email"]}</p>
              )}
            </div>

            <div className="field has-addons mb-0">
              <div className="control" style={{ width: "100%" }}>
                <input
                  className="input is-medium"
                  type={values.passwordVisible ? "text" : "password"}
                  placeholder="Passwort"
                  onChange={onInput}
                  name="password"
                  value={values.password}
                />
              </div>
              <div className="control">
                <button
                  className="button is-secondary is-medium"
                  type="button"
                  onClick={() =>
                    setValues({
                      ...values,
                      passwordVisible: !values.passwordVisible,
                    })
                  }
                >
                  {values.passwordVisible ? (
                    <i className="fas fa-eye-slash"></i>
                  ) : (
                    <i className="fas fa-eye"></i>
                  )}
                </button>
              </div>
            </div>
            {values.errors["password"] && (
              <p className="help is-danger">{values.errors["password"]}</p>
            )}
            {values.errors["loginError"] && (
              <article className="message is-danger  mt-3">
                <div className="message-body">
                  {values.errors["loginError"]}
                </div>
              </article>
            )}
            <button
              className="button is-block is-primary is-fullwidth mt-3"
              disabled={values.loading}
            >
              Registrieren
            </button>
          </form>
        </div>
      </div>
    </section>
  );
};

export default SignUp;
