import React from "react";

const Print = () => {
  return (
    <button className="button mx-2" onClick={() => window.print()}>
      <i className="fas fa-print"></i>
    </button>
  );
};

export default Print;
