import { format } from "date-fns";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Loader from "../../../components/Loader";
import Notification from "../../../components/Notification";
import { isEmpty } from "../../../utils/functions";
import { supabase } from "../../../utils/supbaseClient";
import { TABLE_NAMES } from "../../../utils/tableNames";

const startYear = 2021;
const currentYear = new Date().getFullYear();
const YEARS = Array(currentYear - startYear + 5)
  .fill(0)
  .map((_, i) => startYear + i);

const pad = (n, width = 2, z) => {
  z = z || "0";
  n = n + "";
  return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
};

const SetFreizeit = () => {
  const [values, setValues] = useState({
    initYear: null,
    year: null,
    start: "",
    end: "",
    deadline_anmeldung: "",
    isActive: false,
    prize_children: 0,
    prize_teenies: 0,
    exists: false,
    loading: true,
    message: {},
  });

  const { uuid } = useParams();

  useEffect(() => {
    (async () => {
      const { data: freizeiten, error } = await supabase
        .from(TABLE_NAMES.seasons)
        .select(
          `year, start, end, isActive, public_uuid, prize_children, prize_teenies, deadline_anmeldung`
        )
        .eq("public_uuid", uuid)
        .limit(1);

      if (error) {
        console.error({ error });
        setValues((state) => ({
          ...state,
          message: { type: "error", text: error.message },
          loading: false,
        }));
        return;
      }

      if (freizeiten.length === 0) {
        setValues((state) => ({ ...state, loading: false }));
        return;
      }
      const f = freizeiten[0];

      setValues((state) => ({
        ...state,
        exists: true,
        loading: false,
        initYear: f.year,
        year: f.year,
        start: f.start,
        end: f.end,
        isActive: f.isActive,
        prize_children: f.prize_children,
        prize_teenies: f.prize_teenies,
        deadline_anmeldung: f.deadline_anmeldung,
      }));
    })();
    return () => {};
  }, [uuid]);

  const validate = () => {
    const errors = {};
    const year = parseInt(values.year);
    if (isNaN(year)) {
      errors["year"] = "Jahr erforderlich";
    }
    if (year < startYear) {
      errors["year"] = "Jahr muss größer sein als " + startYear;
    }

    if (isEmpty(errors)) {
      setValues({
        ...values,
        message: {},
      });
      return true;
    } else {
      setValues({
        ...values,
        message: {
          type: "warning",
          text: Object.values(errors).join(" & "),
        },
      });
      return false;
    }
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    if (!validate()) return;

    const payload = {
      year: values.year,
      start: new Date(values.start),
      end: new Date(values.end),
      prize_children: values.prize_children,
      prize_teenies: values.prize_teenies,
      deadline_anmeldung: new Date(values.deadline_anmeldung),
    };

    console.log(payload);

    const { error } = await supabase
      .from(TABLE_NAMES.seasons)
      .update(payload)
      .match({ public_uuid: uuid });

    if (error) {
      console.error(error);
      return;
    }

    setValues({
      ...values,
      message: { type: "success", text: "Änderungen gespeichert!" },
      loading: false,
    });
  };

  if (values.loading) return <Loader />;

  if (!values.exists)
    return (
      <div className="container is-fullwidth">
        <article className="message is-danger">
          <div className="message-header">
            <p>Eintrag nicht gefunden</p>
          </div>
          <div className="message-body">
            Es existiert kein Eintrag mit der ID: "{uuid}"
          </div>
        </article>
      </div>
    );

  const handleInput = (e) =>
    setValues({ ...values, [e.target.name]: e.target.value });

  if (values.loading) return <Loader />;

  const formatDate = (str) => {
    const d = new Date(str);
    return format(d, "yyyy-MM-dd'T'HH:mm:ss");
  };

  const activateEntry = async (e) => {
    const state = e.target.checked;
    setValues({ ...values, loading: true });
    const { data, count } = await supabase
      .from(TABLE_NAMES.seasons)
      .select("year", { count: "exact" })
      .match({ isActive: true })
      .neq("year", values.initYear);

    if (count > 0) {
      setValues({
        ...values,
        loading: false,
        message: {
          text: `Deaktiviere erst die aktivierte Freizeit (${data[0].year}), um diese Freizeit aktivieren zu können`,
          type: "warning",
        },
      });
    } else {
      await supabase
        .from(TABLE_NAMES.seasons)
        .update({ isActive: state })
        .match({ public_uuid: uuid });
      setValues({
        ...values,
        isActive: state,
        loading: false,
        message: { type: "success", text: "Änderungen gespeichert!" },
      });
    }
  };

  return (
    <div className="container is-fullwidth mt-5">
      <h1 className="title">Freizeit</h1>
      {values.message.text && (
        <Notification
          message={values.message.text}
          type={values.message.type}
        />
      )}
      <form onSubmit={onSubmit} noValidate>
        <div className="columns">
          <div className="column">
            <div className="select is-fullwidth mt-5">
              <select
                className="is-fullwidth"
                name="year"
                id="year"
                value={values.year}
                onChange={handleInput}
                required
              >
                <option value="">Jahr auswählen</option>
                {YEARS.map((year) => (
                  <option value={year} key={year}>
                    {year}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="column">
            <div className="field">
              <label htmlFor="deadline_anmeldung">Deadline Anmeldung</label>
              <input
                type="datetime-local"
                id="deadline_anmeldung"
                name="deadline_anmeldung"
                placeholder="Deadline Anmeldung eintragen"
                onChange={handleInput}
                value={formatDate(values.deadline_anmeldung)}
                className="input"
              />
            </div>
          </div>
        </div>

        <div className="columns mt-3">
          <div className="column">
            <div>
              <label htmlFor="start">Startdatum der Freizeit</label>
              <input
                className="input"
                type="datetime-local"
                name="start"
                id="start"
                placeholder="Start Zeitpunkt eintragen"
                onChange={handleInput}
                value={formatDate(values.start)}
                max={formatDate(values.end)}
                required
              />
            </div>
          </div>
          <div className="column">
            <div>
              <label htmlFor="end">Enddatum der Freizeit</label>
              <input
                className="input"
                type="datetime-local"
                name="end"
                id="end"
                placeholder="End Zeitpunkt eintragen"
                onChange={handleInput}
                value={formatDate(values.end)}
                required
              />
            </div>
          </div>
        </div>
        <div className="columns mt-3">
          <div className="column">
            <div>
              <label htmlFor="prize_children">Preis kleine Kinder (€)</label>
              <input
                className="input"
                type="number"
                name="prize_children"
                id="prize_children"
                placeholder="Preis kleine Kinder eintragen"
                onChange={handleInput}
                value={values.prize_children}
                min="0"
                required
              />
            </div>
          </div>
          <div className="column">
            <div>
              <label htmlFor="prize_teenies">Preis große Kinder (€)</label>
              <input
                className="input"
                type="number"
                name="prize_teenies"
                id="prize_teenies"
                placeholder="Preis große Kinder eintragen"
                onChange={handleInput}
                value={values.prize_teenies}
                min="0"
                required
              />
            </div>
          </div>
        </div>

        <div className="has-text-right mt-5">
          <button className="button is-primary" type="submit">
            Speichern
          </button>
        </div>
      </form>

      <label className="checkbox">
        <input
          type="checkbox"
          id="isActive"
          name="isActive"
          checked={values.isActive}
          onChange={activateEntry}
        />
        {" Freizeit aktivieren"}
      </label>
    </div>
  );
};

export default SetFreizeit;
