import React, { useState, useEffect } from "react";
import { supabase } from "../../../utils/supbaseClient";
import MsgImg from "./../../../assets/message.png";
import "bulma-list/css/bulma-list.css";
import { formatDistance } from "date-fns";
import { de } from "date-fns/locale";
import { useNavigate } from "react-router-dom";
import { MY_CONFIG } from "./../../../utils/config";
import { refactorPath } from "../../../utils/functions";
import Loader from "../../../components/Loader";
import { TABLE_NAMES } from "../../../utils/tableNames";

const MessageItem = ({
  name,
  extract,
  createdAt,
  allowArchive,
  allowDelete,
  onOpen,
  onArchive,
  isArchived,
}) => {
  return (
    <div className="list-item">
      <div className="list-item-image">
        <figure className="image is-48x48">
          <img src={MsgImg} alt="Nachricht" />
        </figure>
      </div>

      <div className="list-item-content">
        <div className="list-item-title is-flex is-justify-content-space-between">
          <span>{name}</span>
        </div>
        <div className="list-item-description">{extract}</div>
        <span className="has-text-weight-normal has-text-grey">
          {formatDistance(createdAt, new Date(), { locale: de })}
        </span>
      </div>

      <div>
        <div className="buttons">
          {allowArchive && (
            <button className="button" onClick={onArchive}>
              <span className="icon">
                {isArchived ? (
                  <i className="fas fa-archive"></i>
                ) : (
                  <i className="fas fa-folder"></i>
                )}
              </span>
            </button>
          )}

          {allowDelete && (
            <button className="button">
              <span className="icon">
                <i className="fas fa-trash"></i>
              </span>
            </button>
          )}

          <button className="button" onClick={onOpen}>
            <span className="icon">
              <i className="fas fa-envelope"></i>
            </span>
          </button>
        </div>
      </div>
    </div>
  );
};

const adjectives = {
  archive: "archivierten",
};

const Messages = () => {
  const [values, setValues] = useState({
    messages: [],
    selectedFilter: "open",
    loading: true,
  });

  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      const { data, error } = await supabase
        .from(TABLE_NAMES.websiteMessages)
        .select(
          "firstName, lastName, email, content, created_at, public_uuid, isArchived"
        )
        .order("created_at", { ascending: false })
        .eq("isArchived", false);

      if (error) {
        console.error(error);
      }

      setValues((state) => ({ ...state, messages: data, loading: false }));
    })();
    return () => {};
  }, []);

  const filter = async (f) => {
    let newMsgs = values.messages;

    switch (f) {
      case "open":
        const { data: archiveData, error: archiveError } = await supabase
          .from(TABLE_NAMES.websiteMessages)
          .select(
            "firstName, lastName, email, content, created_at, public_uuid, isArchived"
          )
          .order("created_at", { ascending: false })
          .eq("isArchived", false);

        if (archiveError) console.error(archiveError);
        newMsgs = archiveData;
        break;
      case "archive":
        const { data, error } = await supabase
          .from(TABLE_NAMES.websiteMessages)
          .select(
            "firstName, lastName, email, content, created_at, public_uuid, isArchived"
          )
          .order("created_at", { ascending: false })
          .eq("isArchived", true);

        if (error) console.error(error);
        newMsgs = data;
        break;
      default:
        break;
    }

    setValues({ ...values, selectedFilter: f, messages: newMsgs });
  };

  if (values.loading) {
    return <Loader />;
  }

  return (
    <div className="container is-fullwidth">
      <div className="box mt-3">
        <div className="columns">
          <div className="column">
            <h1 className="title is-4 mb-2">Messages</h1>
          </div>
          <div className="column has-text-right">
            <div className="select">
              <select
                value={values.selectedFilter}
                onChange={(e) => filter(e.target.value)}
                name="filter"
              >
                <option value="open">Offen</option>
                <option value="archive">Archiviert</option>
              </select>
            </div>
          </div>
        </div>

        <div className="list has-hoverable-list-items has-overflow-ellipsis">
          {values.messages.map((msg, index) => (
            <MessageItem
              key={index}
              name={`${msg.firstName} ${msg.lastName}`}
              extract={
                msg.content.substring(0, 50) +
                (msg.content.length > 50 ? "..." : "")
              }
              createdAt={new Date(msg.created_at)}
              onOpen={() =>
                navigate(
                  refactorPath(
                    MY_CONFIG.routes.manager.setMessage.path,
                    ":uuid",
                    msg.public_uuid
                  )
                )
              }
              onArchive={async () => {
                await supabase
                  .from(TABLE_NAMES.websiteMessages)
                  .update({ isArchived: !msg.isArchived })
                  .match({ public_uuid: msg.public_uuid });
                const newMessages = values.messages.filter(
                  (m) => m.public_uuid !== msg.public_uuid
                );
                console.log(newMessages);
                setValues({
                  ...values,
                  messages: newMessages,
                });
              }}
              allowArchive
            />
          ))}
        </div>
        {values.messages.length === 0 && (
          <h3 className="subtitle">
            Keine {adjectives[values.selectedFilter]} Nachrichten gefunden
          </h3>
        )}
      </div>
    </div>
  );
};

export default Messages;
