import React, { useState } from "react";

const ColumnsVisibility = ({ columns, onChange }) => {
  const [open, setOpen] = useState(false);

  const selectColumn = (index) => {
    const newColumns = columns;
    newColumns[index].hide = !newColumns[index].hide;

    onChange(newColumns);
  };

  const toggleAll = () => {
    const someVisible = columns.some((col) => col.hide);
    onChange(columns.map((col) => ({ ...col, hide: !someVisible })));
  };

  return (
    <div className={`dropdown is-hoverable`}>
      <div className="dropdown-trigger">
        <button
          className="button"
          aria-haspopup="true"
          aria-controls="dropdown-menu"
          onClick={() => setOpen(!open)}
        >
          <span>Spalten</span>
          <span className="icon is-small">
            <i className="fas fa-angle-down" aria-hidden="true"></i>
          </span>
        </button>
      </div>
      <div className="dropdown-menu" id="dropdown-menu" role="menu">
        <div className="dropdown-content">
          {columns.map((col, index) => (
            <button
              key={index}
              className={
                "dropdown-item" +
                (col.hide ? " is-italic	" : " has-text-weight-bold")
              }
              onClick={() => selectColumn(index)}
            >
              {col.headerName || col.field}
            </button>
          ))}
          <hr className="dropdown-divider" />
          <button className="dropdown-item" onClick={toggleAll}>
            Alle {columns.some((col) => col.hide) ? "anzeigen" : "verstecken"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default ColumnsVisibility;
