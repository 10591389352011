import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Loader from "../../../components/Loader";
import Notification from "../../../components/Notification";
import { isEmpty } from "../../../utils/functions";
import { supabase } from "../../../utils/supbaseClient";
import { TABLE_NAMES } from "../../../utils/tableNames";

const SetKinderstunde = () => {
  const [values, setValues] = useState({
    name: "",
    startAge: 0,
    endAge: 0,
    isTeenie: false,
    prize: 0,
    exists: false,
    loading: true,
    message: {},
  });

  const { uuid } = useParams();

  useEffect(() => {
    (async () => {
      const { data: kinderstunde, error } = await supabase
        .from(TABLE_NAMES.childrensLessons)
        .select(`name, start_age, end_age, isTeenie, prize`)
        .eq("public_uuid", uuid)
        .limit(1);

      if (error) {
        console.error({ error });
        setValues((state) => ({
          ...state,
          message: { type: "error", text: error.message },
          loading: false,
        }));
        return;
      }

      if (kinderstunde.length === 0) {
        setValues((state) => ({ ...state, loading: false }));
        return;
      }
      const k = kinderstunde[0];

      setValues((state) => ({
        ...state,
        exists: true,
        loading: false,
        name: k.name,
        startAge: k.start_age,
        endAge: k.end_age,
        isTeenie: k.isTeenie,
        prize: k.prize,
      }));
    })();
    return () => {};
  }, [uuid]);

  const validate = () => {
    const errors = {};
    if (values.name.trim().length === 0) {
      errors["name"] = "Name der Kinderstunde benötigt";
    }

    if (String(values.startAge).trim().length === 0) {
      errors["startAge"] = "Startalter benötigt";
    }

    if (String(values.endAge).trim().length === 0) {
      errors["endAge"] = "Endalter benötigt";
    }

    if (isNaN(values.startAge)) {
      errors["startAge"] = "Startalter muss eine Zahl sein";
    }

    if (isNaN(values.endAge)) {
      errors["endAge"] = "Endalter muss eine Zahl sein";
    }

    if (values.startAge > 16 || values.startAge < 6) {
      errors["startAge"] = "Alter muss zwischen 6 und 16 sein";
    }

    if (values.endAge > 16 || values.endAge < 6) {
      errors["endAge"] = "Alter muss zwischen 6 und 16 sein";
    }

    if (isEmpty(errors)) {
      setValues({
        ...values,
        message: {},
      });
      return true;
    } else {
      setValues({
        ...values,
        message: {
          type: "warning",
          text: Object.values(errors).join(" & "),
        },
      });
      return false;
    }
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    if (!validate()) return;

    const { error } = await supabase
      .from(TABLE_NAMES.childrensLessons)
      .update({
        name: values.name,
        start_age: values.startAge,
        end_age: values.endAge,
        isTeenie: values.isTeenie,
        prize: values.prize,
      })
      .match({ public_uuid: uuid });

    if (error) {
      console.error(error);
      return;
    }

    setValues({
      ...values,
      message: { type: "success", text: "Änderungen gespeichert!" },
      loading: false,
    });
  };

  if (values.loading) return <Loader />;

  if (!values.exists)
    return (
      <div className="container is-fullwidth">
        <article className="message is-danger">
          <div className="message-header">
            <p>Eintrag nicht gefunden</p>
          </div>
          <div className="message-body">
            Es existiert kein Eintrag mit der ID: "{uuid}"
          </div>
        </article>
      </div>
    );

  const handleInput = (e) =>
    setValues({ ...values, [e.target.name]: e.target.value });

  return (
    <div className="container is-fullwidth mt-5">
      <h1 className="title">Kinderstunde</h1>
      {values.message.text && (
        <Notification
          message={values.message.text}
          type={values.message.type}
        />
      )}
      <form onSubmit={onSubmit} noValidate>
        <div>
          <label htmlFor="name">Name</label>
          <input
            className="input"
            type="text"
            name="name"
            id="name"
            placeholder="Kinderstundennamen eintragen"
            onChange={handleInput}
            value={values.name}
            required
          />
        </div>

        <div className="columns mt-3">
          <div className="column">
            <div>
              <label htmlFor="startAge">Start Alter</label>
              <input
                className="input"
                type="number"
                name="startAge"
                id="startAge"
                placeholder="Startalter der Kinderstunde eintragen"
                onChange={handleInput}
                value={values.startAge}
                required
              />
            </div>
          </div>
          <div className="column">
            <div>
              <label htmlFor="endAge">End Alter</label>
              <input
                className="input"
                type="number"
                name="endAge"
                id="endAge"
                placeholder="Endalter der Kinderstunde eintragen"
                onChange={handleInput}
                value={values.endAge}
                required
              />
            </div>
          </div>
        </div>

        <div className="select is-fullwidth mt-5">
          <select
            className="is-fullwidth"
            value={values.isTeenie ? "large" : "small"}
            onChange={(e) =>
              setValues({ ...values, isTeenie: e.target.value === "large" })
            }
          >
            <option value="large">Große Kinder</option>
            <option value="small">Kleine Kinder</option>
          </select>
        </div>

        <div className="has-text-right mt-5">
          <button className="button is-primary" type="submit">
            Speichern
          </button>
        </div>
      </form>

      <div className="mt-3">
        <Notification
          message="Änderungen auf der Homepage werden erst nach einer Regenerierung der Homepage durch den Webadmin sichtbar."
          type="warning"
          hideCloseButton
        />
      </div>
    </div>
  );
};

export default SetKinderstunde;
