import React, { useEffect, useState } from "react";
import { supabase } from "./../utils/supbaseClient";

const StatsTile = ({ table, title, colorClass, onClick }) => {
  const [count, setCount] = useState(null);

  useEffect(() => {
    (async () => {
      const { count } = await supabase
        .from(table)
        .select("id", { count: "exact" });

      setCount(count);
    })();
    return () => {};
  }, [table]);

  return (
    <div
      className={`tile is-parent ${onClick ? "is-clickable" : ""}`}
      onClick={onClick}
    >
      <article
        className={`tile is-child box notification ${colorClass && colorClass}`}
      >
        <p className="title">{count === null ? "Laden..." : count}</p>
        <p className="subtitle">{title || ""}</p>
      </article>
    </div>
  );
};

export default StatsTile;
