import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import { MY_CONFIG } from "./config";
import { supabase } from "./supbaseClient";

export const RequireAuth = ({ children }) => {
  const location = useLocation();

  const user = supabase.auth.user();

  if (!user) {
    return (
      <Navigate
        to={MY_CONFIG.routes.auth.login}
        state={{ from: location }}
        replace
      />
    );
  }

  return children;
};
