import React, { useState } from "react";
import { resolveValue } from "./helpers";

const Searchbar = ({ rows, columns, onChange, ...props }) => {
  const [text, setText] = useState("");

  const onSearch = (val) => {
    setText(val);
    val = val.trim();

    const regExp = new RegExp(val, "gi");

    const filteredRows = rows.filter((row) =>
      columns.some(({ field, customSearchFieldPath }) => {
        let formattedValue = resolveValue(customSearchFieldPath || field, row);
        if (typeof formattedValue === "object" && formattedValue.title) {
          formattedValue = formattedValue.title;
        }
        const tmp = regExp.test(formattedValue);
        return tmp;
      })
    );
    onChange(filteredRows);
  };

  return (
    <div {...props}>
      <div className="control has-icons-right">
        <input
          type="text"
          className="input"
          placeholder="Suchen"
          value={text}
          onChange={(e) => {
            onSearch(e.target.value);
          }}
          onFocus={(e) => e.target.select()}
        />
        <span className="icon is-small is-right">
          <i className="fas fa-search"></i>
        </span>
      </div>
    </div>
  );
};

export default Searchbar;
