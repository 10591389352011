import React from "react";

const ConfirmDialog = ({ isDelete, onClose, onAccept, text }) => {
  return (
    <div className={`modal ${"is-active"}`}>
      <div className="modal-background" onClick={onClose}></div>
      <div className="modal-card">
        <header className="modal-card-head has-background-danger has-text-white">
          <p className="modal-card-title has-text-white	">
            {isDelete ? "Eintrag löschen?" : "Bestätigen"}
          </p>
          <button className="delete" aria-label="close" onClick={onClose}></button>
        </header>
        <section className="modal-card-body">
          {text || "Möchtest du wirklich fortfahren?"}
        </section>
        <footer className="modal-card-foot is-flex is-justify-content-space-between">
          <button className="button" onClick={onClose}>
            Abbrechen
          </button>
          <button
            className={`button ${isDelete ? "is-danger" : "is-success"}`}
            onClick={onAccept}
          >
            {isDelete ? "Löschen" : "Bestätigen"}
          </button>
        </footer>
      </div>
    </div>
  );
};

export default ConfirmDialog;
