import React, { useState } from "react";
import { MY_CONFIG } from "../utils/config";
import Logo from "./../assets/logo.png";
import "@creativebulma/bulma-badge/dist/bulma-badge.min.css";
import { handleLogout } from "../utils/functions";
import { Link, NavLink } from "react-router-dom";

const Navbar = ({ profile }) => {
  const [open, setOpen] = useState(false);
  const [megaMenuOpen, setMegaMenuOpen] = useState(false);

  const ROUTES = Object.values(MY_CONFIG.routes.user).filter(
    (path) => path.isVisible
  );

  const activeClassName = "is-active";

  const onClickLink = () => {
    setOpen(false);
    setMegaMenuOpen(false);
  };

  return (
    <nav className="navbar has-shadow is-fixed-top">
      <div className="container">
        <div className="navbar-brand">
          <Link className="navbar-item" to={MY_CONFIG.routes.user.home.path}>
            <img src={Logo} alt="Logo" />
          </Link>

          <a
            role="button"
            className={"navbar-burger " + (open ? "is-active" : "")}
            aria-label="menu"
            aria-expanded="false"
            onClick={(e) => {
              e.preventDefault();
              setOpen(!open);
              setMegaMenuOpen(false);
            }}
            href="/"
          >
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
          </a>
        </div>
        <div className={"navbar-menu " + (open ? "is-active" : "")}>
          <div className="navbar-end">
            {ROUTES.map((r, index) => (
              <NavLink
                className={({ isActive }) =>
                  isActive ? `navbar-item ${activeClassName}` : "navbar-item"
                }
                key={index}
                to={r.path}
                onClick={onClickLink}
              >
                {r.title}
              </NavLink>
            ))}

            {profile.roles.value >= 10 && (
              <div className="navbar-item has-dropdown is-mega">
                <div
                  className="navbar-link"
                  onClick={() => setMegaMenuOpen(!megaMenuOpen)}
                >
                  Manager
                </div>
                <div
                  id="blogDropdown"
                  className="navbar-dropdown"
                  style={{ display: megaMenuOpen ? "block" : "none" }}
                >
                  <div className="container is-fluid">
                    <div className="columns">
                      {MY_CONFIG.navigationLayout.manager.map((col, index) => {
                        if (!col.title)
                          return <div className="column" key={index}></div>;
                        return (
                          <div className="column" key={index}>
                            <h1 className="title is-6 is-mega-menu-title">
                              {col.title}
                            </h1>
                            {col.routes.map((routeKey) => {
                              const route = MY_CONFIG.routes.manager[routeKey];
                              const path = route.path;

                              return (
                                <NavLink
                                  to={path}
                                  key={routeKey}
                                  onClick={onClickLink}
                                  className={({ isActive }) =>
                                    isActive
                                      ? `navbar-item ${activeClassName}`
                                      : "navbar-item"
                                  }
                                >
                                  <div className="navbar-item">
                                    <div className="navbar-content">
                                      <p>
                                        <small className="has-text-info">
                                          {route.title}
                                        </small>
                                      </p>
                                      <p>{route.short}</p>
                                    </div>
                                  </div>
                                </NavLink>
                              );
                            })}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
            )}
            <NavLink
              className={({ isActive }) =>
                isActive ? `navbar-item ${activeClassName}` : "navbar-item"
              }
              to={MY_CONFIG.routes.user.settings.path}
              onClick={onClickLink}
            >
              {MY_CONFIG.routes.user.settings.title}
            </NavLink>

            <a className="navbar-item" href="/" onClick={handleLogout}>
              <span className="icon">
                <i className="fas fa-sign-out-alt"></i>
              </span>
            </a>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
