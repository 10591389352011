import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Loader from "../../../components/Loader";
import Table from "../../../components/Table";
import { MY_CONFIG } from "../../../utils/config";
import { refactorPath } from "../../../utils/functions";
import { supabase } from "../../../utils/supbaseClient";
import { TABLE_NAMES } from "../../../utils/tableNames";

const columns = [
  { headerName: "ID", field: "id", hide: true },
  { headerName: "Jahr", field: "year" },
  { headerName: "Start", field: "start", type: "date" },
  { headerName: "Ende", field: "end", type: "date" },
  { headerName: "Deadline", field: "deadline_anmeldung", type: "date" },
  {
    headerName: "Aktiv",
    field: "isActive",
    formatCell: (value) => (value ? "Aktiviert" : "Deaktiviert"),
    hide: true,
  },
  { headerName: "Preis (Kleine)", field: "prize_children" },
  { headerName: "Preis (Große)", field: "prize_teenies" },
];

const Freizeiten = () => {
  const [values, setValues] = useState({ freizeiten: [], loading: true });

  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      const { data, error } = await supabase.from(TABLE_NAMES.seasons).select(`
          id,
          year,
          start,
          end,
          isActive, 
          public_uuid,
          prize_children,
          prize_teenies,
          deadline_anmeldung
        `);

      if (error) {
        console.error(error);
      }
      setValues((state) => ({ ...state, freizeiten: data, loading: false }));
    })();
    return () => {};
  }, []);

  const onEdit = (data) => {
    navigate(
      refactorPath(
        MY_CONFIG.routes.manager.setFreizeit.path,
        ":uuid",
        data.public_uuid
      )
    );
  };

  if (values.loading) {
    return <Loader />;
  }

  return (
    <div className="container is-fullwidth pt-4">
      <h1 className="title">Freizeiten</h1>
      <Table
        columns={columns}
        data={values.freizeiten}
        loading={values.loading}
        onEdit={onEdit}
        isBordered
      />
    </div>
  );
};

export default Freizeiten;
