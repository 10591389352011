import { supabase } from "./supbaseClient";

export const isEmpty = (obj) => {
  for (var prop in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, prop)) {
      return false;
    }
  }

  return JSON.stringify(obj) === JSON.stringify({});
};

export const validateEmail = (email) => {
  /*eslint-disable */
  const re =
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  /*eslint-enable */
  return re.test(email);
};

export const handleLogout = async (e) => {
  e.preventDefault();
  try {
    await supabase.auth.signOut();
  } catch (error) {}
};

export const refactorPath = (path, key, id) => {
  return key ? path.replace(key, id) : path;
};
