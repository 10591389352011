import React, { useState, useEffect } from "react";
import { supabase } from "./../utils/supbaseClient";
import SelectableList from "./SelectableList";
import ConfirmDialog from "./ConfirmDialog";
import { TABLE_NAMES } from "../utils/tableNames";

const styles = {
  content: {
    position: "absolute",
    right: 0,
    top: 0,
    bottom: 0,
    maxWidth: 400,
    maxHeight: "100vh",
    padding: "1rem 0 0 1rem",
    margin: 0,
    display: "flex",
  },
  header: {
    paddingTop: 5,
    paddingLeft: 5,
  },
  mainContent: {
    maxHeight: "100vh",
    width: "100%",
  },
  bottomBar: {
    width: "100%",
    padding: "0 1rem 1rem",
  },
};
const MergeSidedrawer = ({ open, onClose, currentId }) => {
  const [values, setValues] = useState({
    anmeldungen: [],
    selectedRegistration: null,
    showMergeConfirm: false,
    loading: true,
  });

  useEffect(() => {
    (async () => {
      const { data, error } = await supabase
        .from(TABLE_NAMES.registrations)
        .select("id, names")
        .neq("id", currentId)
        .order("names");

      if (error) console.error(error);

      setValues((state) => ({ ...state, anmeldungen: data, loading: false }));
    })();
    return () => {};
  }, [currentId]);

  const onSubmit = (e) => {
    e.preventDefault();
    setValues({ ...values, showMergeConfirm: true });
  };

  const onMerge = async () => {
    const { error: err_children } = await supabase
      .from(TABLE_NAMES.registeredChildren)
      .update({ anmeldung: currentId })
      .match({ anmeldung: values.selectedRegistration });

    if (err_children) {
      console.error(err_children);
      alert("Ein unerwarteter Fehler ist aufgetreten");
      return;
    }

    const { error } = await supabase
      .from(TABLE_NAMES.registrations)
      .delete()
      .match({ id: values.selectedRegistration });

    if (error) {
      console.error(error);
      alert("Ein unerwarteter Fehler ist aufgetreten");
      return;
    }

    window.location.reload();
  };

  return (
    <div className={`modal ${open ? "is-active" : ""}`}>
      <div className="modal-background" onClick={onClose}></div>
      <div
        className="modal-content has-background-white"
        style={styles.content}
      >
        <button
          className="modal-close is-large has-background-grey"
          aria-label="close"
          onClick={onClose}
        ></button>
        <form
          onSubmit={onSubmit}
          className="is-flex is-flex-direction-column"
          style={styles.mainContent}
        >
          <h1 className="subtitle" style={styles.header}>
            Anmeldungen zusammenführen
          </h1>

          {values.loading ? (
            <div className="pt-5 px-5">
              <progress
                className="progress is-small is-primary"
                max="100"
              ></progress>
            </div>
          ) : (
            <SelectableList
              value={values.selectedRegistration}
              onChange={(value) => {
                setValues({ ...values, selectedRegistration: value });
              }}
              list={values.anmeldungen.map((reg) => ({
                value: reg.id,
                text: reg.names,
              }))}
              loading={values.loading}
            />
          )}

          <div
            className="has-background-white is-flex is-justify-content-space-between is-align-items-center"
            style={styles.bottomBar}
          >
            <p className="is-6">
              {
                values.anmeldungen.find(
                  (i) => i.id === values.selectedRegistration
                )?.names
              }
            </p>
            <div className="has-text-right">
              <button
                className="button is-warning"
                type="submit"
                disabled={
                  values.loading || values.selectedRegistration === null
                }
              >
                Zusammenführen
              </button>
            </div>
          </div>
        </form>
      </div>
      {values.showMergeConfirm && (
        <ConfirmDialog
          onAccept={onMerge}
          onClose={() => setValues({ ...values, showMergeConfirm: false })}
          text={`Möchtest du wirklich die Anmeldungen zusammenführen? Dabei wird die ausgewählte Anmeldung gelöscht und die Kinder der aktuellen Anmeldung hinzugefügt`}
        />
      )}
    </div>
  );
};

export default MergeSidedrawer;
