import React, { useState } from "react";

const SelectableList = ({ list, value, onChange, loading }) => {
  const [text, setText] = useState("");
  const [rows, setRows] = useState(list);

  if (value === undefined || onChange === undefined) {
    console.error("value or onChange is missing");
    return <></>;
  }

  const onSearch = (val) => {
    setText(val);
    val = val.trim();

    if (val.length === 0) {
      val = "(.*?)";
    }
    const regExp = new RegExp(val, "gi");

    const filteredRows = list.filter((entry) => {
      const tmp = regExp.test(entry.text);
      return tmp || entry.value === value;
    });
    setRows(filteredRows);
  };

  return (
    <div className="selectableList">
      <div className="control has-icons-right searchBar mr-3">
        <input
          type="text"
          className="input"
          placeholder="Suchen"
          value={text}
          onChange={(e) => {
            onSearch(e.target.value);
          }}
        />
        <span className="icon is-small is-right">
          <i className="fas fa-search"></i>
        </span>
      </div>
      <div className="panel scrollbar">
        {rows.map(({ text, value: val }) => (
          <a
            className={`panel-block ${value === val ? "is-active" : ""}`}
            onClick={(e) => {
              e.preventDefault();
              onChange(val);
            }}
            key={val}
            href={"#" + val}
          >
            <span className="panel-icon">
              <i className="fas fa-list" aria-hidden="true"></i>
            </span>
            {text}
          </a>
        ))}
      </div>
      <span>
        {rows.length} von {list.length} Einträge
      </span>
    </div>
  );
};

export default SelectableList;
