import React from "react";
import { isEmpty, validateEmail } from "../../utils/functions";
import { supabase } from "../../utils/supbaseClient";
import Notification from "../Notification";

const ResetPassword = () => {
  const [values, setValues] = React.useState({
    email: "",
    errors: {},
    message: {},
  });

  const validate = () => {
    const newErrors = {};

    if (values.email.length === 0) {
      newErrors["email"] = "E-Mail erforderlich";
    } else {
      if (!validateEmail(values.email)) {
        newErrors["email"] = "Keine gültige E-Mail";
      }
    }
    setValues({ ...values, errors: newErrors, message: {} });
    return isEmpty(newErrors);
  };

  const onSubmit = (e) => {
    e.preventDefault();

    if (!validate()) return;

    const { error } = supabase.auth.api.resetPasswordForEmail(values.email);

    if (error) {
      const newErrors = {
        loginError: error.message,
      };
      setValues({ ...values, errors: newErrors });

      console.error(error);
      return;
    }

    setValues({
      ...values,
      errors: {},
      message: { text: "Zurücksetzungs E-Mail versendet", type: "success" },
    });
  };

  const onInput = (e) =>
    setValues({ ...values, [e.target.name]: e.target.value });

  return (
    <section
      style={{
        maxWidth: "600px",
      }}
    >
      <div className="columns">
        <div className="column left has-text-centered">
          <h1 className="title is-3">Passwort zurücksetzen</h1>
          <h1 className="subtitle is-5 has-text-centered">Verwaltung</h1>

          {values.message.text && (
            <Notification
              message={values.message.text}
              type={values.message.type}
            />
          )}
          <form className="mt-3" onSubmit={onSubmit} noValidate>
            <div className="field">
              <div className="control">
                <input
                  className="input is-medium"
                  type="email"
                  autoComplete="off"
                  placeholder="name@mail.com"
                  onChange={onInput}
                  name="email"
                />
              </div>
              {values.errors["email"] && (
                <p className="help is-danger">{values.errors["email"]}</p>
              )}
            </div>

            {values.errors["loginError"] && (
              <article className="message is-danger  mt-3">
                <div className="message-body">
                  {values.errors["loginError"]}
                </div>
              </article>
            )}
            <button
              className="button is-block is-primary is-fullwidth mt-3"
              type="submit"
            >
              Zurücksetzen
            </button>
          </form>
        </div>
      </div>
    </section>
  );
};

export default ResetPassword;
