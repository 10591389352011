import React, { useState, useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { RequireAuth } from "./utils/authHelpers";
import Layout from "./utils/Layout";
import { MY_CONFIG } from "./utils/config";
import { supabase } from "./utils/supbaseClient";

import Error404 from "./views/shared/Error404";
import AuthView from "./views/auth/AuthView";

import ComingSoon from "./views/shared/ComingSoon";
import PasswordRecovery from "./views/shared/PasswordRecovery";
import Loader from "./components/Loader";
import UserNotConfirmed from "./views/shared/UserNotConfirmed";
import { ProfileContext } from "./utils/ProfileContext";
import { TABLE_NAMES } from "./utils/tableNames";

const STATES = {
  LOADING: "loading",
  UNAUTHENTICATED: "unauthenticated",
  ACTIVE: "active",
  PASSWORD_RECOVERY: "password_recovery",
  INACTIVE: "inactive",
};

function App() {
  const [values, setValues] = useState({
    state: "loading",
    user: supabase.auth.user(),
    profile: null,
  });

  useEffect(() => {
    const { unsubscribe } = supabase.auth.onAuthStateChange(
      async (event, session) => {
        let profile = values.profile || null;
        let user = supabase.auth.user();
        let newState = values.state || event;
        switch (event) {
          case "SIGNED_IN":
            profile = values.profile || (await getProfile());
            user = session.user;
            newState = profile.isActive ? STATES.ACTIVE : STATES.INACTIVE;
            break;
          case "SIGNED_OUT":
            user = null;
            profile = null;
            window.location.reload();
            return;
          case "TOKEN_REFRESHED":
          case "USER_UPDATED":
            profile = values.profile || (await getProfile());
            newState = profile.isActive ? STATES.ACTIVE : STATES.INACTIVE;
            break;
          case "USER_DELETED":
            break;
          case "PASSWORD_RECOVERY":
            newState = STATES.PASSWORD_RECOVERY;
            break;
          default:
            profile = values.profile || (await getProfile());
            newState = profile.isActive ? STATES.ACTIVE : STATES.INACTIVE;
            break;
        }
        setValues((state) => ({
          ...state,
          state: newState,
          profile,
          user,
          rank: profile?.role?.value,
        }));
      }
    );
    (async () => {
      const user = supabase.auth.user();
      if (!user) {
        setValues((state) => ({ ...state, state: STATES.UNAUTHENTICATED }));
      } else {
        const profile = values.profile || (await getProfile());
        const newState = profile?.isActive ? STATES.ACTIVE : STATES.INACTIVE;
        setValues((state) => ({ ...state, state: newState, profile, user }));
      }
    })();
    return () => {
      unsubscribe && unsubscribe();
    };
  }, [values.profile, values.state]);

  const getProfile = async () => {
    const user = supabase.auth.user();
    if (!user) return null;

    const { data, error } = await supabase
      .from(TABLE_NAMES.profiles)
      .select(`firstName, lastName, roles (id, name, value), isActive`)
      .eq("id", user.id);

    if (error) {
      console.error(error);
      return null;
    }

    return data[0];
  };

  let paths = Object.values(MY_CONFIG.routes.user);
  if (values.profile?.roles.value >= 10) {
    paths = [...paths, ...Object.values(MY_CONFIG.routes.manager)];
  }

  if (values.state === STATES.LOADING) return <Loader />;

  const getEnhancedComponent = (component) => {
    return component;
  };

  return (
    <ProfileContext.Provider value={values.profile}>
      <BrowserRouter>
        {values.user ? (
          <Routes>
            {values.state === STATES.PASSWORD_RECOVERY && (
              <Route path="*" key={-2} element={<PasswordRecovery />} />
            )}

            {values.state === STATES.INACTIVE && (
              <Route path="*" key={-2} element={<UserNotConfirmed />} />
            )}

            {values.state === STATES.ACTIVE && (
              <Route element={<Layout profile={values.profile} />}>
                {paths.map((r, i) => {
                  const path = r.path;

                  return (
                    <Route
                      key={i}
                      index={r.index}
                      path={path}
                      element={
                        <RequireAuth>
                          {r.comingSoon ? (
                            <ComingSoon />
                          ) : (
                            getEnhancedComponent(r.component)
                          )}
                        </RequireAuth>
                      }
                    />
                  );
                })}
                <Route path="*" key={-1} element={<Error404 />} />
              </Route>
            )}
          </Routes>
        ) : (
          <Routes>
            <Route path="*" element={<AuthView />} />
          </Routes>
        )}
      </BrowserRouter>
    </ProfileContext.Provider>
  );
}

export default App;
