import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Loader from "../../../components/Loader";
import Table from "../../../components/Table";
import { MY_CONFIG } from "../../../utils/config";
import { refactorPath } from "../../../utils/functions";
import { supabase } from "../../../utils/supbaseClient";
import { TABLE_NAMES } from "../../../utils/tableNames";

const columns = [
  { headerName: "ID", field: "id", hide: true },
  { headerName: "Name", field: "name" },
  { headerName: "Start", field: "start_age" },
  { headerName: "Ende", field: "end_age" },
  {
    headerName: "Altersgruppe",
    field: "isTeenie",
    formatCell: (value) => (value ? "Groß" : "Klein"),
  },
];

const Kinderstunden = () => {
  const [values, setValues] = useState({ kinderstunden: [], loading: true });

  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      const { data, error } = await supabase.from(TABLE_NAMES.childrensLessons)
        .select(`
          id,
          name,
          start_age,
          end_age,
          isTeenie, 
          public_uuid
        `);

      if (error) {
        console.error(error);
      }
      setValues((state) => ({ ...state, kinderstunden: data, loading: false }));
    })();
    return () => {};
  }, []);

  const onEdit = (data) => {
    navigate(
      refactorPath(
        MY_CONFIG.routes.manager.setKinderstunde.path,
        ":uuid",
        data.public_uuid
      )
    );
  };

  if (values.loading) {
    return <Loader />;
  }

  return (
    <div className="container is-fullwidth pt-4">
      <h1 className="title">Angemeldete Kinder</h1>
      <Table
        columns={columns}
        data={values.kinderstunden}
        loading={values.loading}
        onEdit={onEdit}
        isBordered
      />
    </div>
  );
};

export default Kinderstunden;
