import React, { useEffect, useState } from "react";
import { supabase } from "../../../utils/supbaseClient";
import { TABLE_NAMES } from "../../../utils/tableNames";

const RoleSelector = ({ ...props }) => {
  const [values, setValues] = useState({
    roles: [],
    loading: true,
    error: "",
  });

  useEffect(() => {
    (async () => {
      const { data, error } = await supabase
        .from(TABLE_NAMES.roles)
        .select("id, name")
        .eq("is_hidden", false);

      if (error) {
        console.error(error);
        setValues((state) => ({
          ...state,
          loading: false,
          error: error.message,
        }));

        return;
      }

      setValues((state) => ({ ...state, roles: data, loading: false }));
    })();

    return () => {};
  }, []);

  if (!props.value) props.value = 1;
  if (!props.onChange) props.readOnly = true;

  return (
    <>
      {values.loading ? (
        <progress
          className="progress is-small my-auto mx-auto"
          max="100"
          style={{ maxWidth: 300 }}
        ></progress>
      ) : (
        <div className="select is-fullwidth">
          <select {...props}>
            {values.roles.map((k) => (
              <option value={k.id} key={k.id}>
                {k.name}
              </option>
            ))}
          </select>
        </div>
      )}
    </>
  );
};

export default RoleSelector;
