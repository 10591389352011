import Dashboard from "./../views/user/Dashboard";
import Registrations from "./../views/user/registrations/Registrations";
import SetRegistration from "./../views/user/registrations/SetRegistration";
import ChildrenTable from "./../views/user/children/ChildrenTable";
import SetChild from "./../views/user/children/SetChild";
import Settings from "./../views/shared/Settings";
import Users from "../views/manager/users/Users";
import SetUser from "../views/manager/users/SetUser";
import Kinderstunden from "../views/manager/kinderstunden/Kinderstunden";
import SetKinderstunde from "../views/manager/kinderstunden/SetKinderstunde";
import Freizeiten from "../views/manager/freizeiten/Freizeiten";
import SetFreizeit from "../views/manager/freizeiten/SetFreizeit";
import Messages from "./../views/manager/messages/Messages";
import SetMessage from "./../views/manager/messages/SetMessage";

export const MY_CONFIG = {
  routes: {
    auth: {
      index: {
        path: `/`,
        title: "Startseite",
      },
      login: {
        path: `/login`,
        title: "Einloggen",
      },
      register: {
        path: `/register`,
        title: "Registrieren",
      },
      resetPassword: {
        path: `/reset`,
        title: "Passwort zurücksetzen",
      },
    },
    user: {
      home: {
        path: `/`,
        title: "Übersicht",
        isVisible: true,
        component: <Dashboard />,
        index: true,
      },
      registrations: {
        path: `/registrations`,
        isVisible: true,
        title: "Anmeldungen",
        component: <Registrations />,
      },
      setRegistration: {
        path: `/registration/:uuid`,
        title: "Anmeldung",
        component: <SetRegistration />,
      },
      children: {
        path: `/children`,
        isVisible: true,
        title: "Kinder",
        component: <ChildrenTable />,
      },
      setChild: {
        path: `/child/:uuid`,
        title: "Kind",
        component: <SetChild />,
      },
      settings: {
        path: `/settings`,
        isVisible: false,
        title: "Einstellungen",
        component: <Settings />,
      },
    },
    manager: {
      users: {
        path: `/users`,
        isVisible: true,
        title: "Benutzer",
        short: "Benutzer aktivieren & bearbeiten",
        component: <Users />,
      },
      setUser: {
        path: `/user/:uuid`,
        title: "Benutzer",
        component: <SetUser />,
      },
      messages: {
        path: `/messages`,
        isVisible: true,
        title: "Nachrichten",
        short: "Nachrichten bearbeiten",
        component: <Messages />,
      },
      setMessage: {
        path: `/message/:uuid`,
        title: "Nachricht",
        component: <SetMessage />,
      },
      kinderstunden: {
        path: `/kinderstunden`,
        title: "Kinderstunden",
        short: "Kinderstunden bearbeiten",
        component: <Kinderstunden />,
      },
      setKinderstunde: {
        path: `/kinderstunde/:uuid`,
        title: "Kinderstunde",
        component: <SetKinderstunde />,
      },
      freizeiten: {
        path: `/freizeiten`,
        title: "Freizeiten",
        short: "Freizeiten bearbeiten",
        component: <Freizeiten />,
      },
      setFreizeit: {
        path: `/freizeit/:uuid`,
        title: "Freizeit",
        component: <SetFreizeit />,
      },
    },
  },
  navigationLayout: {
    manager: [
      {},
      {},
      { title: "Stammdaten", routes: ["freizeiten", "kinderstunden"] },
      {
        title: "Administration",
        routes: ["messages", "users"],
      },
    ],
  },
};

export const LOCAL_STORAGE_KEYS = {
  KINDERSTUNDEN: "ks",
};
