import React from "react";

const ShirtSizeInfo = () => {
  const [open, setOpen] = React.useState(false);
  return (
    <span>
      <div className={"modal" + (open ? " is-active" : "")}>
        <div className="modal-background" onClick={() => setOpen(false)}></div>
        <div className="modal-content">
          <div className="table-container box">
            <table className="table is-fullwidth">
              <thead>
                <tr>
                  <th>Alter</th>
                  <th>Körpergrösse in cm</th>
                  <th>T-Shirt Grösse</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>3 – 4 Jahre</td>
                  <td>104</td>
                  <td>104</td>
                </tr>
                <tr>
                  <td>4 – 5 Jahre</td>
                  <td>110</td>
                  <td>110</td>
                </tr>
                <tr>
                  <td>5 – 6 Jahre</td>
                  <td>116</td>
                  <td>116</td>
                </tr>
                <tr>
                  <td>6 – 7 Jahre</td>
                  <td>122</td>
                  <td>122</td>
                </tr>
                <tr>
                  <td>7 – 8 Jahre</td>
                  <td>128</td>
                  <td>128</td>
                </tr>
                <tr>
                  <td>8 – 9 Jahre</td>
                  <td>134</td>
                  <td>134</td>
                </tr>
                <tr>
                  <td>9 – 10 Jahre</td>
                  <td>140</td>
                  <td>140</td>
                </tr>
                <tr>
                  <td>10 – 11 Jahre</td>
                  <td>146</td>
                  <td>146</td>
                </tr>
                <tr>
                  <td>11 – 12 Jahre</td>
                  <td>152</td>
                  <td>152</td>
                </tr>
                <tr>
                  <td>12 – 13 Jahre</td>
                  <td>158</td>
                  <td>158</td>
                </tr>
                <tr>
                  <td>13 – 14 Jahre</td>
                  <td>164</td>
                  <td>164</td>
                </tr>
                <tr>
                  <td>14 Jahre plus</td>
                  <td>170</td>
                  <td>170</td>
                </tr>
                <tr>
                  <td>15 Jahre</td>
                  <td>182</td>
                  <td>182</td>
                </tr>
                <tr>
                  <td>16 Jahre &amp; älter</td>
                  <td>188</td>
                  <td>188</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <button
          className="modal-close is-large"
          aria-label="close"
          onClick={() => setOpen(false)}
        ></button>
      </div>
      <button
        style={{ background: "none", border: "none" }}
        onClick={() => setOpen(!open)}
      >
        <i className="fas fa-question"></i>
      </button>
    </span>
  );
};

export default ShirtSizeInfo;
