import React, { useEffect, useState } from "react";
import Loader from "../../components/Loader";
import Notification from "../../components/Notification";
import { isEmpty, validateEmail } from "../../utils/functions";
import { supabase } from "../../utils/supbaseClient";
import { TABLE_NAMES } from "../../utils/tableNames";

const Settings = () => {
  const [values, setValues] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    passwordVisible: false,
    errors: {},
    message: {},
    loading: true,
  });

  const user = supabase.auth.user();

  useEffect(() => {
    (async () => {
      const { data, error } = await supabase
        .from(TABLE_NAMES.profiles)
        .select("firstName, lastName, role")
        .eq("id", user.id)
        .single();

      if (error) console.error(error);

      setValues((state) => ({
        ...state,
        firstName: data.firstName || "",
        lastName: data.lastName || "",
        email: user.email || "",
        loading: false,
      }));
    })();

    return () => {};
  }, [user.id, user.email]);

  const validate = () => {
    const newErrors = {};

    if (values.email.length === 0) {
      newErrors["email"] = "E-Mail erforderlich";
      if (!validateEmail(values.email)) {
        newErrors["email"] = "Keine gültige E-Mail";
      }
    }

    if (values.password.length > 0 && values.password.length < 7) {
      newErrors["password"] =
        "Das Passwort muss mindestens 6 Zeichen lang sein";
    }

    setValues({ ...values, errors: newErrors });

    return isEmpty(newErrors);
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    if (!validate()) return;

    setValues({ ...values, loading: true });

    const payload = {
      firstName: values.firstName,
      lastName: values.lastName,
      email: values.email,
    };

    const updates = {
      data: payload,
      email: values.email,
    };

    if (values.password >= 6) {
      updates.password = values.password;
    }

    const { error: localError } = await supabase.auth.update(updates);
    if (localError) {
      console.error(localError);
      setValues({
        ...values,
        message: { type: "error", text: localError.message },
        loading: false,
      });
      return;
    }

    const { error: dbError } = await supabase
      .from(TABLE_NAMES.profiles)
      .update(payload)
      .match({ id: user.id });

    if (dbError) {
      console.error(dbError);
      setValues({
        ...values,
        message: { type: "error", text: dbError.message },
        loading: false,
      });
      return;
    }

    setValues({
      ...values,
      message: { type: "success", text: "Gespeichert" },
      password: "",
      loading: false,
    });
  };

  const onInput = (e) =>
    setValues({ ...values, [e.target.name]: e.target.value });

  if (values.loading) return <Loader />;

  return (
    <div className="container mt-5">
      <h1 className="title">Einstellungen</h1>
      {values.message.text && (
        <Notification
          message={values.message.text}
          type={values.message.type}
        />
      )}
      <form className="mt-3" onSubmit={onSubmit} noValidate>
        <div className="columns mb-1">
          <div className="field column mb-1">
            <div className="control">
              <label htmlFor="firstName">Vorname</label>
              <input
                className="input"
                type="text"
                placeholder="Vornamen eintragen"
                onChange={onInput}
                name="firstName"
                autoComplete="firstName"
                value={values.firstName}
              />
            </div>
            {values.errors["firstName"] && (
              <p className="help is-danger">{values.errors["firstName"]}</p>
            )}
          </div>
          <div className="field column mb-1">
            <div className="control">
              <label htmlFor="lastName">Nachname</label>
              <input
                className="input"
                type="text"
                placeholder="Nachnamen eintragen"
                onChange={onInput}
                name="lastName"
                id="lastName"
                autoComplete="lastName"
                value={values.lastName}
              />
            </div>
            {values.errors["lastName"] && (
              <p className="help is-danger">{values.errors["lastName"]}</p>
            )}
          </div>
        </div>

        <div className="columns mb-1">
          <div className="field column">
            <div className="control">
              <label htmlFor="email">E-Mail</label>
              <input
                className="input"
                type="text"
                placeholder="E-Mail eintragen"
                onChange={onInput}
                name="email"
                id="email"
                autoComplete="email"
                value={values.email}
              />
            </div>
            {values.errors["email"] && (
              <p className="help is-danger">{values.errors["email"]}</p>
            )}
          </div>
        </div>

        <div className="field has-addons mb-0 mt-1">
          <div className="control" style={{ width: "100%" }}>
            <label htmlFor="password">Neues Passwort</label>
            <input
              className="input"
              type={values.passwordVisible ? "text" : "password"}
              placeholder="Neues Passwort eingeben"
              onChange={onInput}
              id="password"
              name="password"
              autoComplete="new-password"
              value={values.password}
            />
          </div>
          <div className="control">
            <label htmlFor="password">&nbsp;</label>
            <button
              className="button is-secondary"
              type="button"
              onClick={() =>
                setValues({
                  ...values,
                  passwordVisible: !values.passwordVisible,
                })
              }
            >
              {values.passwordVisible ? (
                <i className="fas fa-eye-slash"></i>
              ) : (
                <i className="fas fa-eye"></i>
              )}
            </button>
          </div>
        </div>
        {values.errors["password"] && (
          <p className="help is-danger">{values.errors["password"]}</p>
        )}

        {values.errors["loginError"] && (
          <article className="message is-danger  mt-3">
            <div className="message-body">{values.errors["loginError"]}</div>
          </article>
        )}

        <div className="has-text-right mt-4">
          <button
            className="button is-primary"
            type="submit"
            disabled={values.loading}
          >
            Speichern
          </button>
        </div>
      </form>
    </div>
  );
};

export default Settings;
