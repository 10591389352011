import React, { useState } from "react";

const Notification = ({ message, type, hideCloseButton }) => {
  const [open, setOpen] = useState(true);

  switch (type) {
    case "success":
      type = "is-success";
      break;
    case "error":
      type = "is-danger";
      break;
    case "warning":
      type = "is-warning";
      break;
    default:
      type = "is-info";
      break;
  }

  return (
    <div className={`notification ${type}` + (open ? "" : " is-hidden")}>
      {!hideCloseButton && (
        <button className="delete" onClick={() => setOpen(!open)}></button>
      )}
      {message || ""}
    </div>
  );
};

export default Notification;
