import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Loader from "../../../components/Loader";
import Notification from "../../../components/Notification";
import SelectKinderstunde from "../../../components/SelectKinderstunde";
import { supabase } from "./../../../utils/supbaseClient";
import { isEmpty, refactorPath } from "./../../../utils/functions";
import { TSHIRTSIZES } from "./../../../utils/options";
import { MY_CONFIG } from "../../../utils/config";
import ConfirmDialog from "../../../components/ConfirmDialog";
import { TABLE_NAMES } from "../../../utils/tableNames";

const SetChild = () => {
  const [values, setValues] = useState({
    exists: false,
    loading: true,
    message: {},
    id: "",
    age: -1,
    status: "",
    firstName: "",
    kinderstunde: "",
    lastName: "",
    phoneParents: "",
    specialInfo: "",
    tShirtSize: -1,
    anmeldung: null,
    created_at: null,
  });

  const { uuid } = useParams();

  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      const { data, error } = await supabase
        .from(TABLE_NAMES.registeredChildren)
        .select(
          `id, public_uuid, age, anmeldung (public_uuid, names), firstName, kinderstunde (id, name),
           lastName, phoneParents, specialInfo, tShirtSize, created_at`
        )
        .eq("public_uuid", uuid);

      if (error) {
        console.error(error);
        setValues((state) => ({
          ...state,
          message: {
            text: error.text,
            type: "error",
          },
          loading: false,
        }));

        return;
      }

      if (data.length === 0) {
        setValues((state) => ({ ...state, exists: false, loading: false }));
        return;
      }

      setValues((state) => ({
        ...state,
        exists: true,
        loading: false,
        ...data[0],
        kinderstunde: data[0]?.kinderstunde.id,
      }));
    })();

    return () => {};
  }, [uuid]);

  if (values.loading) return <Loader />;

  if (!values.exists)
    return (
      <div className="container is-fullwidth">
        <article className="message is-danger">
          <div className="message-header">
            <p>Eintrag nicht gefunden</p>
          </div>
          <div className="message-body">
            Es existiert kein Eintrag mit der ID: "{uuid}"
          </div>
        </article>
      </div>
    );

  const handleInput = (e) =>
    setValues({ ...values, [e.target.name]: e.target.value });

  const validate = () => {
    const errors = {};
    if (values.firstName.trim().length === 0) {
      errors["firstName"] = "Vorname benötigt";
    }
    if (values.lastName.trim().length === 0) {
      errors["lastName"] = "Nachname benötigt";
    }

    if (String(values.age).trim().length === 0) {
      errors["age"] = "Alter benötigt";
    }

    if (isNaN(values.age)) {
      errors["age"] = "Alter muss eine Zahl sein";
    }

    if (values.age > 18 || values.age < 1) {
      errors["age"] = "Alter muss zwischen 1 und 18 sein";
    }

    if (isEmpty(errors)) {
      setValues({
        ...values,
        message: {},
      });
      return true;
    } else {
      setValues({
        ...values,
        message: {
          type: "warning",
          text: Object.values(errors).join(" & "),
        },
      });
      return false;
    }
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    if (!validate()) return;

    const { error } = await supabase
      .from(TABLE_NAMES.registeredChildren)
      .update({
        firstName: values.firstName,
        lastName: values.lastName,
        age: values.age,
        kinderstunde: values.kinderstunde,
        phoneParents: values.phoneParents,
        tShirtSize: values.tShirtSize,
        specialInfo: values.specialInfo,
      })
      .match({ public_uuid: uuid });

    if (error) {
      console.error(error);
      return;
    }

    setValues({
      ...values,
      message: { type: "success", text: "Änderungen gespeichert!" },
      loading: false,
    });
  };

  const onDelete = async () => {
    const { error } = await supabase
      .from(TABLE_NAMES.registeredChildren)
      .delete()
      .match({ id: values.id });

    if (error) console.error(error);
    navigate(
      refactorPath(
        MY_CONFIG.routes.user.setRegistration.path,
        ":uuid",
        values.anmeldung?.public_uuid
      )
    );
  };

  return (
    <div className="container is-fullwidth">
      <div className="columns pt-3">
        <div className="column is-half">
          <h1 className="title">Kind</h1>
        </div>
        <div className="column is-half has-text-right">
          <Link
            className="subtitle"
            to={refactorPath(
              MY_CONFIG.routes.user.setRegistration.path,
              ":uuid",
              values.anmeldung?.public_uuid
            )}
          >
            <button className="button is-info">Anmeldung</button>
          </Link>

          <button
            className="button is-danger ml-3"
            onClick={() => setValues({ ...values, showDeleteDialog: true })}
          >
            <span className="icon is-small">
              <i className="fas fa-trash"></i>
            </span>
            <span>Löschen</span>
          </button>
        </div>
      </div>
      <div className="columns">
        <div className="column">
          <h3 className="subtitle">{values.anmeldung?.names}</h3>
        </div>
      </div>
      {values.message.text && (
        <Notification
          message={values.message.text}
          type={values.message.type}
        />
      )}
      <form onSubmit={onSubmit} noValidate>
        <div className="columns">
          <div className="column">
            <div>
              <label className="label" htmlFor="firstName">
                Vorname
              </label>
              <input
                className="input"
                type="text"
                name="firstName"
                id="firstName"
                placeholder="Vornamen eintragen"
                onChange={handleInput}
                value={values.firstName}
                required
              />
            </div>
          </div>
          <div className="column">
            <div>
              <label className="label" htmlFor="lastName">
                Nachname
              </label>
              <input
                className="input"
                type="text"
                name="lastName"
                id="lastName"
                placeholder="Nachnamen eintragen"
                onChange={handleInput}
                value={values.lastName}
                required
              />
            </div>
          </div>
        </div>

        <div className="columns">
          <div className="column">
            <div>
              <label className="label" htmlFor="age">
                Alter
              </label>
              <input
                className="input"
                type="number"
                min="1"
                max="18"
                name="age"
                id="age"
                placeholder="Alter eintragen"
                onChange={handleInput}
                value={values.age}
                required
              />
            </div>
          </div>
          <div className="column">
            <SelectKinderstunde
              name="kinderstunde"
              id="kinderstunde"
              onChange={(e) =>
                setValues({
                  ...values,
                  kinderstunde: e.target.value,
                })
              }
              value={values.kinderstunde}
            />
          </div>
        </div>

        <div className="columns">
          <div className="column">
            <div>
              <label className="label" htmlFor="phoneParents">
                Handy/Telefon Erziehungsberechtigter
              </label>
              <p className="control has-icons-left">
                <input
                  className="input"
                  type="text"
                  name="phoneParents"
                  id="phoneParents"
                  placeholder="Telefon- oder Handynummer eines Erziehungsberechtigten eintragen"
                  onChange={handleInput}
                  value={values.phoneParents}
                  required
                />
                <span className="icon is-small is-left">
                  <i className="fas fa-phone"></i>
                </span>
              </p>
            </div>
          </div>
          <div className="column">
            <label className="label" htmlFor="tShirtSize">
              T-Shirt Größe
            </label>
            <div className="select is-fullwidth">
              <select
                value={values.tShirtSize}
                onChange={handleInput}
                name="tShirtSize"
                id="tShirtSize"
              >
                <option value="None">Keine Größe</option>
                {TSHIRTSIZES.map((size) => (
                  <option value={size} key={size}>
                    {size}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>

        <div className="columns">
          <div className="column is-full">
            <label className="label" htmlFor="specialInfo">
              Sonstige Bemerkungen
            </label>
            <textarea
              id="specialInfo"
              name="specialInfo"
              onChange={handleInput}
              value={values.specialInfo}
              className="textarea"
              placeholder="Sonstige Bemerkungen (z.B. Allergien, Medikationen)"
            ></textarea>
          </div>
        </div>

        <div className="has-text-right">
          <button className="button is-primary" type="submit">
            Speichern
          </button>
        </div>
      </form>
      {values.showDeleteDialog && (
        <ConfirmDialog
          onAccept={onDelete}
          onClose={() => setValues({ ...values, showDeleteDialog: false })}
          text={`Möchtest du wirklich die dieses Kind aus der Datenbank löschen?`}
          isDelete
        />
      )}
    </div>
  );
};

export default SetChild;
