import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Loader from "../../../components/Loader";
import Table from "../../../components/Table";
import { MY_CONFIG } from "../../../utils/config";
import { refactorPath } from "../../../utils/functions";
import { supabase } from "../../../utils/supbaseClient";
import { TABLE_NAMES } from "../../../utils/tableNames";

const columns = [
  { headerName: "ID", field: "id", type: "number", hide: true },
  { headerName: "Vorname", field: "firstName", type: "string" },
  { headerName: "Nachname", field: "lastName", type: "string" },
  { headerName: "E-Mail", field: "email", type: "string" },
  {
    headerName: "Status",
    field: "isActive",
    formatCell: (value) => (value ? "Aktiviert" : "Deaktiviert"),
  },
  { headerName: "Rolle", field: "role.name" },
  {
    headerName: "Registriert am",
    field: "created_at",
    type: "date",
    hide: true,
  },
];

const Users = () => {
  const [values, setValues] = useState({ users: [], loading: true });

  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      const { data, error } = await supabase.from(TABLE_NAMES.profiles).select(`
          id,
          firstName,
          lastName,
          email,
          isActive,
          role (id, name, value),
          created_at
        `);

      if (error) {
        console.error(error);
      }

      setValues((state) => ({ ...state, users: data, loading: false }));
    })();
    return () => {};
  }, []);

  const onEdit = (data) => {
    navigate(
      refactorPath(MY_CONFIG.routes.manager.setUser.path, ":uuid", data.id)
    );
  };

  if (values.loading) {
    return <Loader />;
  }

  return (
    <div className="container is-fullwidth pt-4">
      <h1 className="title">Benutzer</h1>
      <Table
        columns={columns}
        data={values.users}
        loading={values.loading}
        onEdit={onEdit}
        isBordered
      />
    </div>
  );
};

export default Users;
