import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Loader from "../../../components/Loader";
import Table from "../../../components/Table";
import { MY_CONFIG } from "../../../utils/config";
import { refactorPath } from "../../../utils/functions";
import { supabase } from "../../../utils/supbaseClient";
import { TABLE_NAMES } from "../../../utils/tableNames";

const columns = [
  { headerName: "ID", field: "id", hide: true },
  { headerName: "Vorname", field: "firstName" },
  { headerName: "Nachname", field: "lastName" },
  { headerName: "Alter", field: "age" },
  { headerName: "Telefon", field: "phoneParents" },
  {
    headerName: "Kinderstunde",
    field: "kinderstunde.name",
    customSortFieldPath: "kinderstunde.name",
  },
  { headerName: "T-Shirt Größe", field: "tShirtSize" },
  { headerName: "Bemerkung", field: "specialInfo", hide: true },
  { headerName: "Erstellt am", field: "created_at", type: "date", hide: true },
];

const ChildrenTable = () => {
  const [values, setValues] = useState({ rows: [], loading: true });

  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      const { data, error } = await supabase.from(
        TABLE_NAMES.registeredChildren
      ).select(`
          id,
          firstName,
          lastName,
          age,
          kinderstunde (id, name),
          tShirtSize,
          phoneParents,
          specialInfo,
          created_at, 
          public_uuid
        `);

      if (error) {
        console.error(error);
      }

      const rows = data
        .map((item) => {
          return {
            ...item,
            created_at: new Date(item.created_at),
            kinderstunde: item.kinderstunde,
          };
        })
        .sort((a, b) => a.created_at - b.created_at);

      setValues((state) => ({ ...state, rows: rows, loading: false }));
    })();
    return () => {};
  }, []);

  const onEdit = (data) => {
    navigate(
      refactorPath(
        MY_CONFIG.routes.user.setChild.path,
        ":uuid",
        data.public_uuid
      )
    );
  };

  if (values.loading) {
    return <Loader />;
  }

  return (
    <div className="container is-fullwidth pt-4">
      <h1 className="title">Angemeldete Kinder</h1>
      <Table
        columns={columns}
        data={values.rows}
        loading={values.loading}
        onEdit={onEdit}
        print={true}
        isBordered
      />
    </div>
  );
};

export default ChildrenTable;
