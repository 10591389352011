import React from "react";
import { handleLogout } from "../../utils/functions";

const UserNotConfirmed = () => {
  return (
    <div className="container">
      <h1 className="title">Account deaktiviert</h1>
      <h3 className="subtitle">
        Dein Account muss aus Sicherheitsgründen noch von einem Admin
        freigeschaltet werden.
      </h3>
      <div className="pt-3 is-flex is-justify-content-center">
        <button className="button is-warning" onClick={handleLogout}>
          Abmelden
        </button>
        <button
          className="button is-primary ml-5"
          onClick={() => window.location.reload()}
        >
          Neuladen
        </button>
      </div>
    </div>
  );
};

export default UserNotConfirmed;
