export const de_DE = {
  "User already registered": "Benutzer bereits registriert",
  "Unable to validate email address: invalid format":
    "E-Mail Adresse kann nicht validiert werden: ungültiges Format",
  "Invalid login credentials": "Ungültige Anmeldedaten",
  "Email not confirmed": "E-Mail nicht bestätigt",
};

const DEFAULT_LANGUAGE = de_DE;

export const translate = (message) => {
  return DEFAULT_LANGUAGE[message] || message;
};
