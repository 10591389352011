export const OPTIONS = {
  signUp_status: {
    pending: { title: "Ausstehend", index: 1 },
    declined: { title: "Abgelehnt", index: 2 },
    accepted: { title: "Akzeptiert", index: 3 },
  },
};

export const TSHIRTSIZES = [
  104, 110, 116, 122, 128, 134, 140, 146, 152, 158, 164, 170, 182, 188,
];
