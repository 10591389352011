import React from "react";
import ResetPassword from "../../components/auth/ResetPassword";
import SignIn from "../../components/auth/SignIn";
import SignUp from "../../components/auth/SignUp";
import { MY_CONFIG } from "../../utils/config";

const AuthView = () => {
  const currentPath = window?.location.pathname;

  const { index, login, register, resetPassword } = MY_CONFIG.routes.auth;

  let renderMe = <SignIn />;
  switch (currentPath) {
    case register.path:
      renderMe = <SignUp />;
      break;
    case resetPassword.path:
      renderMe = <ResetPassword />;
      break;
    default:
      break;
  }

  return (
    <div className="container is-flex is-align-items-center is-flex-direction-column is-justify-content-center">
      <div className="box">{renderMe}</div>
      <div className="is-flex is-justify-content-space-between">
        {currentPath !== login.path && currentPath !== index.path && (
          <a href={login.path}>Anmelden</a>
        )}
        {currentPath !== register.path && (
          <a href={register.path} className="ml-3">
            Registrieren
          </a>
        )}
        {currentPath !== resetPassword.path && (
          <a href={resetPassword.path} className="ml-3">
            Passwort zurücksetzen
          </a>
        )}
      </div>
    </div>
  );
};

export default AuthView;
