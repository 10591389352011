import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Loader from "../../../components/Loader";
import Notification from "../../../components/Notification";
import { MY_CONFIG } from "../../../utils/config";
import { isEmpty } from "../../../utils/functions";
import { supabase } from "../../../utils/supbaseClient";
import ConfirmDialog from "../../../components/ConfirmDialog";
import RoleSelector from "./RoleSelector";
import { TABLE_NAMES } from "../../../utils/tableNames";

const SetUser = () => {
  const [values, setValues] = useState({
    id: "",
    firstName: "",
    lastName: "",
    email: "",
    role: 1,
    isActive: false,
    errors: {},
    message: {},
    loading: true,
    showDeleteDialog: false,
  });
  const { uuid } = useParams();
  const navigate = useNavigate();
  useEffect(() => {
    (async () => {
      const { data, error } = await supabase
        .from(TABLE_NAMES.profiles)
        .select(
          "id, firstName, lastName, role, email, isActive, role (id, name)"
        )
        .eq("id", uuid)
        .single();

      if (error) console.error(error);

      setValues((state) => ({
        ...state,
        id: data.id,
        firstName: data.firstName || "",
        lastName: data.lastName || "",
        email: data.email,
        isActive: data.isActive,
        role: data.role?.id,
        loading: false,
      }));
    })();

    return () => {};
  }, [uuid]);

  const validate = () => {
    const newErrors = {};

    setValues({ ...values, errors: newErrors });

    return isEmpty(newErrors);
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    if (!validate()) return;
    setValues({ ...values, loading: true });

    const payload = {
      firstName: values.firstName,
      lastName: values.lastName,
      email: values.email,
      isActive: Boolean(values.isActive),
      role: parseInt(values.role),
    };

    const { error } = await supabase
      .from(TABLE_NAMES.profiles)
      .update(payload)
      .match({ id: uuid });

    if (error) {
      console.error(error);
      setValues({
        ...values,
        message: { type: "error", text: error.message },
        loading: false,
      });
      return;
    }

    setValues({
      ...values,
      message: { type: "success", text: "Gespeichert" },
      loading: false,
    });
  };
  const onInput = (e) =>
    setValues({ ...values, [e.target.name]: e.target.value });

  if (values.loading) return <Loader />;

  const onDelete = async () => {
    const { error: userErr } = await supabase
      .from(TABLE_NAMES.profiles)
      .delete()
      .match({ id: values.id });

    if (userErr) console.error(userErr);

    navigate(MY_CONFIG.routes.manager.users.path);
  };

  console.log(values);
  return (
    <div className="container mt-5">
      <h1 className="title">Einstellungen</h1>
      {values.message.text && (
        <Notification
          message={values.message.text}
          type={values.message.type}
        />
      )}
      <form className="mt-3" onSubmit={onSubmit} noValidate>
        <div className="columns">
          <div className="field column mb-1">
            <div className="control">
              <label htmlFor="firstName">Vorname</label>
              <input
                className="input"
                type="text"
                placeholder="Vornamen eintragen"
                onChange={onInput}
                name="firstName"
                autoComplete="firstName"
                value={values.firstName}
              />
            </div>
            {values.errors["firstName"] && (
              <p className="help is-danger">{values.errors["firstName"]}</p>
            )}
          </div>
          <div className="field column mb-1">
            <div className="control">
              <label htmlFor="lastName">Nachname</label>
              <input
                className="input"
                type="text"
                placeholder="Nachnamen eintragen"
                onChange={onInput}
                name="lastName"
                id="lastName"
                autoComplete="lastName"
                value={values.lastName}
              />
            </div>
            {values.errors["lastName"] && (
              <p className="help is-danger">{values.errors["lastName"]}</p>
            )}
          </div>
        </div>

        <div className="columns mb-0">
          <div className="field column pb-0">
            <div className="control">
              <label htmlFor="email">E-Mail</label>
              <input
                className="input"
                type="text"
                autoComplete="off"
                value={values.email}
                title="Die E-Mail kann der nur in den eigenen Einstellungen geändert werden"
                readOnly
                disabled
              />
            </div>
          </div>
        </div>

        {supabase.auth.user().id === uuid && (
          <p className="is-size-7 mb-3">
            Die folgenden Einstellungen kann nur ein anderer Manager ändern.
          </p>
        )}

        <div className="columns mb-1">
          <div className="field column">
            <label htmlFor="role">Rolle</label>
            <RoleSelector
              value={values.role}
              onChange={onInput}
              name="role"
              id="role"
              disabled={supabase.auth.user().id === uuid}
            />
          </div>
        </div>

        <div className="columns mb-1">
          <div className="field column">
            <label className="checkbox">
              <input
                type="checkbox"
                onChange={(e) =>
                  setValues({ ...values, isActive: e.target.checked })
                }
                checked={values.isActive}
                name="isActive"
                id="isActive"
                disabled={supabase.auth.user().id === uuid}
              />{" "}
              Aktiv
            </label>
          </div>
        </div>

        {values.errors["loginError"] && (
          <article className="message is-danger  mt-3">
            <div className="message-body">{values.errors["loginError"]}</div>
          </article>
        )}

        <div className="is-flex is-justify-content-space-between mt-4">
          <button
            className="button is-danger"
            disabled={values.loading}
            type="button"
            onClick={() => setValues({ ...values, showDeleteDialog: true })}
          >
            Löschen
          </button>
          <button
            className="button is-primary"
            type="submit"
            disabled={values.loading}
          >
            Speichern
          </button>
        </div>
      </form>
      {values.showDeleteDialog && (
        <ConfirmDialog
          onAccept={onDelete}
          onClose={() => setValues({ ...values, showDeleteDialog: false })}
          text={`Möchtest du wirklich den Benutzer (${values.email}) löschen?`}
          isDelete
        />
      )}
    </div>
  );
};

export default SetUser;
