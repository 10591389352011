import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import StatsTile from "../../components/StatsTile";
import { MY_CONFIG } from "../../utils/config";
import { refactorPath } from "../../utils/functions";
import { ProfileContext } from "../../utils/ProfileContext";

const Dashboard = () => {
  const profile = useContext(ProfileContext);
  const navigate = useNavigate();

  return (
    <div className="container pt-5 is-fullwidth">
      <h1 className="title">Dashboard</h1>
      <div className="tile has-text-centered">
        <StatsTile
          table="anmeldungen"
          title="Anmeldungen"
          colorClass="is-primary"
          onClick={() =>
            navigate(refactorPath(MY_CONFIG.routes.user.registrations.path))
          }
        />
        <StatsTile
          table="angemeldete_kinder"
          title="Kinder"
          colorClass="is-info"
          onClick={() =>
            navigate(refactorPath(MY_CONFIG.routes.user.children.path))
          }
        />
      </div>
      {profile?.roles?.value >= 10 && (
        <div className="tile has-text-centered">
          <StatsTile
            table="kinderstunden"
            title="Kinderstunden"
            colorClass="is-danger"
            onClick={() =>
              navigate(
                refactorPath(MY_CONFIG.routes.manager.kinderstunden.path)
              )
            }
          />
          <StatsTile
            table="website_messages"
            title="Nachrichten"
            onClick={() =>
              navigate(refactorPath(MY_CONFIG.routes.manager.messages.path))
            }
          />
          <StatsTile
            table="profiles"
            title="Benutzer"
            colorClass="is-warning"
            onClick={() =>
              navigate(refactorPath(MY_CONFIG.routes.manager.users.path))
            }
          />
        </div>
      )}
    </div>
  );
};

export default Dashboard;
