import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ConfirmDialog from "../../../components/ConfirmDialog";
import Loader from "../../../components/Loader";
import Notification from "../../../components/Notification";
import { MY_CONFIG } from "../../../utils/config";
import { supabase } from "../../../utils/supbaseClient";
import { TABLE_NAMES } from "../../../utils/tableNames";

const SetMessage = () => {
  const [values, setValues] = useState({
    id: "",
    name: "",
    email: "",
    content: "",
    exists: false,
    loading: true,
    message: {},
    showDeleteDialog: false,
  });

  const { uuid } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      const { data: message, error } = await supabase
        .from(TABLE_NAMES.websiteMessages)
        .select(
          `id, firstName, lastName, email, content, created_at, public_uuid, isArchived`
        )
        .eq("public_uuid", uuid)
        .limit(1);

      if (error) {
        console.error({ error });
        setValues((state) => ({
          ...state,
          message: { type: "error", text: error.message },
          loading: false,
        }));
        return;
      }

      if (message.length === 0) {
        setValues((state) => ({ ...state, loading: false }));
        return;
      }
      const { firstName, lastName, email, content, id } = message[0];

      setValues((state) => ({
        ...state,
        exists: true,
        loading: false,
        name: `${firstName} ${lastName}`,
        id,
        email,
        content,
      }));
    })();
    return () => {};
  }, [uuid]);

  if (values.loading) return <Loader />;

  if (!values.exists)
    return (
      <div className="container is-fullwidth">
        <article className="message is-danger">
          <div className="message-header">
            <p>Eintrag nicht gefunden</p>
          </div>
          <div className="message-body">
            Es existiert kein Eintrag mit der ID: "{uuid}"
          </div>
        </article>
      </div>
    );

  const onDelete = async () => {
    const { error } = await supabase
      .from(TABLE_NAMES.websiteMessages)
      .delete()
      .match({ id: values.id });

    if (error) console.error(error);

    navigate(MY_CONFIG.routes.manager.messages.path);
  };

  return (
    <div className="container is-fullwidth mt-5">
      <div className="columns">
        <div className="column is-half">
          <h1 className="title">Nachricht</h1>
        </div>
        <div className="column is-half has-text-right">
          <button
            className="button is-danger ml-3"
            onClick={() => setValues({ ...values, showDeleteDialog: true })}
          >
            <span className="icon is-small">
              <i className="fas fa-trash"></i>
            </span>
            <span>Löschen</span>
          </button>
        </div>
      </div>
      {values.message.text && (
        <Notification
          message={values.message.text}
          type={values.message.type}
        />
      )}
      <h1 className="subtitle is-5 my-0">
        {"Von: "}
        {values.name}
      </h1>
      <h3 className="subtitle is-5">
        {"E-Mail: "}
        <a href={`mailto:${values.email}`}>{values.email}</a>
      </h3>
      <h5 className="is-size-5">Nachricht</h5>
      <p className="mt-0 content">{values.content}</p>

      {values.showDeleteDialog && (
        <ConfirmDialog
          onAccept={onDelete}
          onClose={() => setValues({ ...values, showDeleteDialog: false })}
          text={`Möchtest du wirklich diese Nachricht löschen?`}
          isDelete
        />
      )}
    </div>
  );
};

export default SetMessage;
