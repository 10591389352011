import React from "react";
import { isEmpty } from "../../utils/functions";
import { supabase } from "../../utils/supbaseClient";

const PasswordRecovery = () => {
  const [values, setValues] = React.useState({
    password: "",
    passwordVisible: false,
    errors: {},
  });

  const onInput = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const validate = () => {
    const newErrors = {};

    if (values.password.length === 0) {
      newErrors["password"] = "Passwort erforderlich";
    }

    if (values.password.length < 6) {
      newErrors["password"] = "Passwort muss mind. 6 Zeichen lang sein";
    }

    setValues({ ...values, errors: newErrors });

    return isEmpty(newErrors);
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    if (!validate()) return;

    const { error } = await supabase.auth.update({ password: values.password });
    if (error) {
      console.error(error);
      setValues({ ...values, errors: { loginError: error.message } });
    }
  };

  return (
    <section
      className="container"
      style={{
        maxWidth: "600px",
      }}
    >
      <div className="box">
        <div className="columns is-multiline">
          <div className="column">
            <h1 className="title is-3 has-text-centered">
              Passwort zurücksetzen
            </h1>
            <form className="mt-3" onSubmit={onSubmit} noValidate>
              <div className="field has-addons mb-0">
                <div className="control" style={{ width: "100%" }}>
                  <input
                    className="input is-medium"
                    type={values.passwordVisible ? "text" : "password"}
                    placeholder="Passwort"
                    name="password"
                    onChange={onInput}
                    value={values.password}
                  />
                </div>
                <div className="control">
                  <button
                    className="button is-secondary is-medium"
                    type="button"
                    onClick={() =>
                      setValues({
                        ...values,
                        passwordVisible: !values.passwordVisible,
                      })
                    }
                  >
                    {values.passwordVisible ? (
                      <i className="fas fa-eye-slash"></i>
                    ) : (
                      <i className="fas fa-eye"></i>
                    )}
                  </button>
                </div>
              </div>
              {values.errors["password"] && (
                <p className="help is-danger">{values.errors["password"]}</p>
              )}

              {values.errors["loginError"] && (
                <article className="message is-danger  mt-3">
                  <div className="message-body">
                    {values.errors["loginError"]}
                  </div>
                </article>
              )}

              <button
                className="button is-block is-primary is-fullwidth mt-3"
                type="submit"
              >
                Anmelden
              </button>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PasswordRecovery;
