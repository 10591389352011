import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import ChildForm from "../../../components/ChildForm";
import ConfirmDialog from "../../../components/ConfirmDialog";
import Loader from "../../../components/Loader";
import Notification from "../../../components/Notification";
import { MY_CONFIG } from "../../../utils/config";
import { supabase } from "../../../utils/supbaseClient";
import { refactorPath } from "../../../utils/functions";
import MergeSidedrawer from "../../../components/MergeSidedrawer";
import { TABLE_NAMES } from "../../../utils/tableNames";

const newKeyword = "new";

const SetRegistration = () => {
  const [values, setValues] = useState({
    loading: true,
    exists: false,
    id: "",
    names: "",
    public_uuid: "",
    created_at: "",
    children: [],
    message: {},
    selectedChild: undefined,
    modalOpen: false,
    showDeleteDialog: false,
    showMergeDialog: false,
  });

  let { uuid } = useParams();

  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      if (uuid === newKeyword) {
        setValues((state) => ({ ...state, exists: true, loading: false }));
        return;
      }
      const { data: registrations, error } = await supabase
        .from(TABLE_NAMES.registrations)
        .select(
          `id, names, public_uuid, created_at, 
          anmeldung:${TABLE_NAMES.registeredChildren} (id, public_uuid, age, anmeldung, firstName, kinderstunde, 
          lastName, phoneParents, specialInfo, tShirtSize )`
        )
        .eq("public_uuid", uuid)
        .limit(1);

      if (error) {
        console.error({ error });
        setValues((state) => ({
          ...state,
          message: { type: "error", text: error.message },
          loading: false,
        }));
        return;
      }

      if (registrations.length === 0) {
        setValues((state) => ({ ...state, loading: false }));
        return;
      }

      console.log(registrations);

      setValues((state) => ({
        ...state,
        exists: true,
        id: registrations[0].id,
        loading: false,
        children: registrations[0]?.anmeldung,
        ...registrations[0],
      }));
    })();

    return () => {};
  }, [uuid]);

  if (values.loading) return <Loader />;

  if (!values.exists)
    return (
      <div className="container is-fullwidth">
        <article className="message is-danger">
          <div className="message-header">
            <p>Eintrag nicht gefunden</p>
          </div>
          <div className="message-body">
            Es existiert kein Eintrag mit der ID: "{uuid}"
          </div>
        </article>
      </div>
    );

  const onDelete = async () => {
    const { error: regChildrenErr } = await supabase
      .from(TABLE_NAMES.registeredChildren)
      .delete()
      .match({ anmeldung: values.id });

    if (regChildrenErr) console.error(regChildrenErr);

    const { error: registrationErr } = await supabase
      .from(TABLE_NAMES.registrations)
      .delete()
      .match({ id: values.id });

    if (registrationErr) console.error(registrationErr);

    navigate(MY_CONFIG.routes.user.registrations.path);
  };

  const onSaveChild = (child) => {
    let children = values.children;
    console.log(child);
    if (child.id) {
      children = values.children.map((ch) => {
        if (child.id === ch.id) {
          return { ...child, id: child.id };
        }
        return ch;
      });
    } else {
      children.push(child);
    }

    setValues({
      ...values,
      children: children,
      selectedChild: false,
      modalOpen: false,
    });
  };

  const onSubmit = async () => {
    if (values.children.length === 0) {
      setValues({
        ...values,
        message: {
          type: "warning",
          text: "Es muss mind. ein Kind hinzugefügt werden",
        },
        loading: false,
      });
      return;
    }

    const firstNames = new Set(),
      lastNames = new Set();

    values.children.forEach((child) => {
      firstNames.add(child.firstName);
      lastNames.add(child.lastName);
    });

    const names =
      [...firstNames].join(", ") + " (" + [...lastNames].join(", ") + ")";

    const payload = { names };
    if (uuid !== newKeyword && values.id) payload["id"] = values.id;

    console.log(payload);

    const { data: registration, error: regErr } = await supabase
      .from(TABLE_NAMES.registrations)
      .upsert([payload]);
    console.log(values.children, registration);

    if (regErr) {
      console.error(regErr);
      setValues({
        ...values,
        message: {
          type: "error",
          text: "Der Datensatz kann nicht gespeichert werden",
        },
        loading: false,
      });
      return;
    }

    const { error: regChildrenErr } = await supabase
      .from(TABLE_NAMES.registeredChildren)
      .upsert(
        values.children.map((child) => {
          return { ...child, anmeldung: registration[0].id };
        })
      );

    if (regChildrenErr) {
      console.error(regChildrenErr);
      setValues({
        ...values,
        message: {
          type: "error",
          text: "Der Datensatz kann nicht gespeichert werden",
        },
        loading: false,
      });
      return;
    }
    setValues({
      ...values,
      message: {
        type: "success",
        text: "Gespeichert",
      },
      loading: false,
    });
    if (uuid === newKeyword)
      navigate(
        refactorPath(
          MY_CONFIG.routes.user.setRegistration.path,
          ":uuid",
          registration[0].public_uuid
        )
      );
  };

  const removeChild = async (child) => {
    setValues({
      ...values,
      children: values.children.filter((cd) => cd.id !== child.id),
    });
  };

  return (
    <div className="container is-fullwidth pt-5">
      <div className="columns">
        <div className="column is-half">
          <h1 className="title">Anmeldung</h1>
          <h3 className="subtitle">{values.names}</h3>
        </div>
        <div className="column is-half has-text-right">
          {uuid !== newKeyword && (
            <button
              className="button is-warning"
              onClick={() => setValues({ ...values, showMergeDialog: true })}
              style={{ marginRight: 10 }}
            >
              <span>Zusammenführen</span>
            </button>
          )}

          {uuid !== newKeyword && (
            <button
              className="button is-danger"
              onClick={() => setValues({ ...values, showDeleteDialog: true })}
            >
              <span className="icon is-small">
                <i className="fas fa-trash"></i>
              </span>
              <span>Löschen</span>
            </button>
          )}
        </div>
      </div>
      {values.message.text && (
        <Notification
          message={values.message.text}
          type={values.message.type}
        />
      )}
      <div className="columns">
        <div className="column">
          <h4>Angemeldete Kinder</h4>
        </div>
      </div>
      <div className="columns">
        <div className="column">
          <div className="table-container is-fullwidth">
            <div className="has-text-right">
              <button
                className={`button is-primary`}
                onClick={() =>
                  setValues({
                    ...values,
                    modalOpen: true,
                    selectedChild: values.selectedChild,
                  })
                }
              >
                <i className="fa fa-plus"></i>
              </button>
            </div>
            <table className="table is-fullwidth is-striped">
              <thead>
                <tr>
                  <th>Vorname</th>
                  <th>Nachname</th>
                  <th>Alter</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {values.children.map((child, index) => (
                  <tr key={index}>
                    <td>
                      {uuid !== newKeyword ? (
                        <Link
                          to={refactorPath(
                            MY_CONFIG.routes.user.setChild.path,
                            ":uuid",
                            child.public_uuid
                          )}
                        >
                          {child.firstName}
                        </Link>
                      ) : (
                        child.firstName
                      )}
                    </td>
                    <td>{child.lastName}</td>
                    <td>{child.age}</td>
                    <td className="has-text-right">
                      <button
                        className="button is-small is-info"
                        title="Kind bearbeiten"
                        onClick={() => {
                          setValues({
                            ...values,
                            selectedChild: child,
                            modalOpen: true,
                          });
                        }}
                      >
                        <i className="far fa-edit"></i>
                      </button>
                      {!("id" in child) && (
                        <button
                          className="button is-small is-warning ml-3"
                          title="Kind entfernen"
                          onClick={() => removeChild(child)}
                        >
                          <i className="fas fa-trash"></i>
                        </button>
                      )}
                    </td>
                  </tr>
                ))}
                {values.children.length === 0 && (
                  <tr key={-1}>
                    <td colSpan={3}>
                      <span>Keine Kinder eingetragen</span>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div className="columns mt-5 pt-5">
        <div className="column is-half">
          <p>
            {values.created_at
              ? "Anmeldung am: " + new Date(values.created_at).toLocaleString()
              : ""}
          </p>
        </div>
        <div className="column is-half">
          <div className="has-text-right">
            <button className="button is-success" onClick={onSubmit}>
              <span className="icon is-small">
                <i className="fas fa-check"></i>
              </span>
              <span>Speichern</span>
            </button>
          </div>
        </div>
      </div>
      {values.modalOpen && (
        <div
          className={`modal ${values.modalOpen ? "is-active" : ""}`}
          style={{ width: "100%" }}
        >
          <div className="modal-background"></div>
          <div className="modal-card">
            <header className="modal-card-head">
              <p className="modal-card-title">Kind anmelden</p>
              <button
                className="delete"
                aria-label="close"
                onClick={() =>
                  setValues({
                    ...values,
                    modalOpen: false,
                    selectedChild: undefined,
                  })
                }
              ></button>
            </header>
            <section className="modal-card-body">
              <ChildForm initData={values.selectedChild} onSave={onSaveChild} />
            </section>
          </div>
        </div>
      )}

      {values.showDeleteDialog && (
        <ConfirmDialog
          onAccept={onDelete}
          onClose={() => setValues({ ...values, showDeleteDialog: false })}
          text={`Möchtest du wirklich die Anmeldung mit allen dazu gehörigen Kindern (${values.names}) löschen?`}
          isDelete
        />
      )}

      {values.showMergeDialog && (
        <MergeSidedrawer
          currentId={values.id}
          open={values.showMergeDialog}
          onClose={() => setValues({ ...values, showMergeDialog: false })}
        />
      )}
    </div>
  );
};

export default SetRegistration;
