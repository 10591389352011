import React from "react";
import { Link, useNavigate } from "react-router-dom";
import Image404 from "./../../assets/404image.jpg";
const Error404 = () => {
  const navigate = useNavigate();

  return (
    <div className="container pt-5">
      <img src={Image404} alt="404 Seite nicht gefunden" />
      <h1 className="title">404 - Die Seite wurde nicht gefunden</h1>
      <div className="columns">
        <div className="column">
          <button onClick={() => navigate(-1)} className="button">
            Zurück
          </button>
        </div>

        <div className="column has-text-right">
          <Link to="/">Homepage</Link>
        </div>
      </div>
    </div>
  );
};

export default Error404;
