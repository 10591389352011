import React from "react";
import SelectKinderstunde from "./SelectKinderstunde";
import ShirtSizeInfo from "./ShirtSizeInfo";
import { isEmpty } from "../utils/functions";

const tShirtSizes = [
  104, 110, 116, 122, 128, 134, 140, 146, 152, 158, 164, 170, 182, 188,
];

const ChildForm = ({ onSave, initData }) => {
  const [values, setValues] = React.useState({
    firstName: initData?.firstName || "",
    lastName: initData?.lastName || "",
    age: initData?.age || 0,
    kinderstunde: initData?.kinderstunde || "",
    tShirtSize: initData?.tShirtSize || "",
    phoneParents: initData?.phoneParents || "",
    specialInfo: initData?.specialInfo || "",
    errors: {},
  });

  console.log(initData);

  const validate = () => {
    const newErrors = {};
    if (values.firstName.length === 0) {
      newErrors["firstName"] = "Bitte einen Vornamen eintragen";
    }
    if (values.lastName.length === 0) {
      newErrors["lastName"] = "Bitte einen Nachnamen eintragen";
    }
    if (values.age < 1 || values.age > 19) {
      newErrors["age"] = "Bitte ein Alter zwischen 1 und 19 eintragen";
    }
    if (values.kinderstunde.length === 0) {
      newErrors["kinderstunde"] = "Bitte wähle eine Kinderstunde";
    }
    if (values.tShirtSize.length === 0) {
      newErrors["tShirtSize"] = "Bitte wähle eine T-Shirt Größe";
    }
    if (values.phoneParents.length === 0) {
      newErrors["phoneParents"] = "Bitte die Nummer der Eltern eintragen";
    }

    if (!/^[0-9]*$/i.test(values.phoneParents)) {
      newErrors["phoneParents"] = "Bitte nur Zahlen verwenden";
    }
    setValues({ ...values, errors: newErrors });

    return isEmpty(newErrors);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      const child = {
        firstName: values.firstName,
        lastName: values.lastName,
        age: values.age,
        kinderstunde: values.kinderstunde,
        tShirtSize: values.tShirtSize,
        phoneParents: values.phoneParents,
        specialInfo: values.specialInfo,
      };

      if (initData && initData.id) child.id = initData.id;

      onSave(child);
      setValues({
        ...values,
        firstName: "",
        age: 0,
        kinderstunde: "",
        tShirtSize: "",
        phoneParents: "",
        specialInfo: "",
      });
    }
  };

  const onInput = (e) => {
    if (e.target.name === "phoneParents" && parseInt(e.target.value) === 0) {
      return;
    }
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const resetForm = () => {
    setValues({
      ...values,
      firstName: "",
      lastName: "",
      age: 0,
      kinderstunde: "",
      tShirtSize: "",
      phoneParents: "",
      specialInfo: "",
      errors: {},
    });
  };

  return (
    <form noValidate onSubmit={onSubmit}>
      <section className="modal-card-body">
        <div className="columns">
          <div className="field column is-half">
            <label className="label">Vorname</label>
            <div className="control">
              <input
                className="input"
                type="text"
                placeholder="Vornamen des Kindes eintragen"
                value={values.firstName}
                onChange={onInput}
                name="firstName"
                autoFocus
              />
            </div>
            {values.errors["firstName"] && (
              <p className="help is-danger">{values.errors["firstName"]}</p>
            )}
          </div>
          <div className="field column is-half">
            <label className="label">Nachname</label>
            <div className="control">
              <input
                className="input"
                type="text"
                placeholder="Nachname des Kindes eintragen"
                value={values.lastName}
                onChange={onInput}
                name="lastName"
              />
            </div>
            {values.errors["lastName"] && (
              <p className="help is-danger">{values.errors["lastName"]}</p>
            )}
          </div>
        </div>
        <div className="columns">
          <div className="field column is-half">
            <label className="label">Alter</label>
            <div className="control">
              <input
                className="input"
                type="number"
                min="0"
                max="20"
                placeholder="Alter des Kindes eintragen"
                value={values.age}
                onChange={onInput}
                name="age"
              />
            </div>
            {values.errors["age"] && (
              <p className="help is-danger">{values.errors["age"]}</p>
            )}
          </div>
          <div className="column is-half">
            <SelectKinderstunde
              value={values.kinderstunde}
              onChange={(e) => {
                setValues({
                  ...values,
                  kinderstunde: e.target.value,
                });
              }}
              error={values.errors["kinderstunde"]}
            />
          </div>
        </div>
        <div className="columns">
          <div className="column is-half">
            <div className="field">
              <label className="label">
                T-Shirt Größe (Kindergrößen) <ShirtSizeInfo />
              </label>
              <div className="control">
                <div className="select is-fullwidth">
                  <select
                    name="tShirtSize"
                    value={values.tShirtSize}
                    onChange={onInput}
                  >
                    <option value="">T-Shirt Größe auswählen</option>
                    {tShirtSizes.map((size) => (
                      <option key={size} value={size}>
                        {size}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              {values.errors["tShirtSize"] && (
                <p className="help is-danger">{values.errors["tShirtSize"]}</p>
              )}
            </div>
          </div>
          <div className="column field">
            <label className="label">Telefon-/Handynummer der Eltern</label>
            <div className="field-body">
              <div className="field is-expanded">
                <div className="field has-addons">
                  <p className="control">
                    <span className="button is-static">+49</span>
                  </p>
                  <p className="control is-expanded">
                    <input
                      className="input"
                      type="tel"
                      placeholder="Telefon- oder Handynummer der Eltern eintragen"
                      value={values.phoneParents}
                      onChange={onInput}
                      name="phoneParents"
                    />
                  </p>
                </div>
                <div className="is-flex is-justify-content-space-between">
                  {values.errors["phoneParents"] && (
                    <p className="help is-danger">
                      {values.errors["phoneParents"]}
                    </p>
                  )}
                  <p className="help">Keine Null (0) am Anfang eintragen</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="field">
          <label className="label">
            Sonstige Bemerkungen (z.B. Allergien, Medikationen)
          </label>
          <div className="control">
            <textarea
              className="textarea"
              placeholder="Sonstige Bemerkungen eintragen"
              value={values.specialInfo}
              onChange={onInput}
              name="specialInfo"
            ></textarea>
            <span className="is-size-7">
              Diese Angaben, werden von uns besonders vertraulich behandelt
            </span>
          </div>
        </div>
        <div className="is-flex is-justify-content-space-between">
          <button
            className="button is-danger"
            type="button"
            onClick={resetForm}
          >
            Zurücksetzen
          </button>
          <button className="button is-primary" type="submit">
            Speichern
          </button>
        </div>
      </section>
    </form>
  );
};

export default ChildForm;
