import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { isEmpty, validateEmail } from "../../utils/functions";
import { translate } from "../../utils/i18n";
import { supabase } from "../../utils/supbaseClient";
import Notification from "../Notification";

const SignIn = () => {
  const [values, setValues] = React.useState({
    email: "",
    password: "",
    passwordVisible: false,
    errors: {},
    message: {},
  });

  const location = useLocation();
  const navigate = useNavigate();

  const from = location.state?.from?.pathname || "/";

  const onInput = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const validate = () => {
    const newErrors = {};

    if (values.email.length === 0) {
      newErrors["email"] = "E-Mail erforderlich";
      if (!validateEmail(values.email)) {
        newErrors["email"] = "Keine gültige E-Mail";
      }
    }

    if (values.password.length === 0) {
      newErrors["password"] = "Passwort erforderlich";
    }

    setValues({ ...values, errors: newErrors });

    return isEmpty(newErrors);
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    if (!validate()) return;

    const { error } = await supabase.auth.signIn({
      email: values.email,
      password: values.password,
    });

    if (error) {
      console.error(error);
      setValues({
        ...values,
        errors: { loginError: translate(error.message) },
      });
    }

    navigate(from);
  };

  const params = new URLSearchParams(window.location.search);

  if (params.get("m") === "r") {
    values.message = {
      type: "success",
      text: "Bestätigungsemail wurde versendet",
    };
  }

  return (
    <section
      style={{
        maxWidth: "600px",
      }}
    >
      <div className="columns is-multiline">
        <div className="column">
          <h1 className="title is-3 has-text-centered">Login</h1>
          <h3 className="subtitle is-5 has-text-centered">Verwaltung</h3>
          {values.message.text && (
            <Notification
              message={values.message.text}
              type={values.message.type}
            />
          )}
          <form className="mt-3" onSubmit={onSubmit} noValidate>
            <div className="field">
              <div className="control">
                <input
                  className="input is-medium"
                  type="email"
                  placeholder="name@mail.com"
                  onChange={onInput}
                  name="email"
                  autoComplete="email"
                />
              </div>
              {values.errors["email"] && (
                <p className="help is-danger">{values.errors["email"]}</p>
              )}
            </div>

            <div className="field has-addons mb-0">
              <div className="control" style={{ width: "100%" }}>
                <input
                  className="input is-medium"
                  type={values.passwordVisible ? "text" : "password"}
                  placeholder="Passwort"
                  name="password"
                  onChange={onInput}
                />
              </div>
              <div className="control">
                <button
                  className="button is-secondary is-medium"
                  type="button"
                  onClick={() =>
                    setValues({
                      ...values,
                      passwordVisible: !values.passwordVisible,
                    })
                  }
                >
                  {values.passwordVisible ? (
                    <i className="fas fa-eye-slash"></i>
                  ) : (
                    <i className="fas fa-eye"></i>
                  )}
                </button>
              </div>
            </div>
            {values.errors["password"] && (
              <p className="help is-danger">{values.errors["password"]}</p>
            )}

            {values.errors["loginError"] && (
              <article className="message is-danger  mt-3">
                <div className="message-body">
                  {values.errors["loginError"]}
                </div>
              </article>
            )}

            <button
              className="button is-block is-primary is-fullwidth mt-3"
              type="submit"
            >
              Anmelden
            </button>
          </form>
        </div>
      </div>
    </section>
  );
};

export default SignIn;
